/** @jsxImportSource @emotion/react */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { jsx, css } from '@emotion/react'
import { Row, Col, Container, Card } from 'react-bootstrap'
import { size, map, get, head } from 'lodash'
import Loading from './Loading'
import { setModalProps, getModalProps } from '../actions/ui'
import { Modal } from 'react-bootstrap'
import { MobileOnly } from './layout/MobileOnly'
import { DesktopOnly } from './layout/DesktopOnly'
import { PhoneNumber } from './PhoneNumber'
import { CustomerNotificationCard, CustomerNotificationCardHeader } from './layout/CustomerNotificationCard'
import { Timestamp } from './Timestamp'
import { Trans, Translation } from 'react-i18next'
import { CardHeader } from './layout/CardHeader'
import CardParagraphHeading from './layout/CardParagraphHeading'
import CardParagraphSubHeading from './layout/CardParagraphSubHeading'
import BusyMask from './BusyMask'
import CardInfoText from './layout/CardInfoText'
import { BlueButton } from './layout/BlueButton'
import { BlueLinkButton } from './layout/BlueLinkButton'
import { molliePaymentList } from '../actions/mollie_payment'
import { customerSubscriptionPayableList } from '../actions/customer_subscription'
import { Separator } from './layout/Separator'
import CurrencyValue from './CurrencyValue'
import FieldInfoText from './layout/FieldInfoText'
import MainLayout from './MainLayout'
import WrappingBusyMask from './WrappingBusyMask'
import { default_theme as theme } from '../emotion/theme'
import { handleSubmitResult } from '../actions/form'

const MODAL_NAME = "PAY_SUBSCRIPTIONS_NOTIFICATION"

class PaySubscriptionsNotification extends Component {

    constructor(props) {
        super(props)
        this.state = {navigating_to_mollie: false}
    }
    
    componentDidMount() {
        const { dispatch } = this.props
        dispatch(customerSubscriptionPayableList.updateListFilter({payment_is_due: true}))
        dispatch(customerSubscriptionPayableList.fetchListIfNeeded())
    }

    componentDidUpdate() {
        const { dispatch } = this.props
        dispatch(customerSubscriptionPayableList.fetchListIfNeeded())
    }

    onHideModal = () => {
        const { dispatch } = this.props
        dispatch(setModalProps(MODAL_NAME, {show: false}))
    }
    
    onMakePayment = () => {
        const { history, dispatch, subscription, subscriptions } = this.props
        const that = this

        const on_ok = function(mollie_record) {
            if ( mollie_record.checkout_url ) {
                that.setState({navigating_to_mollie: true})
                molliePaymentList.rememberPendingMolliePaymentId(mollie_record.id)
                window.location = mollie_record.checkout_url
            }
        }

        if ( size(subscriptions) > 0 ) {
            history.push("/pay_subscriptions")
        } else {
            molliePaymentList.rememberAsReturnUrl()
            dispatch(molliePaymentList.startPaySubscription({subscription})).then((res) => handleSubmitResult({res, on_ok}))
        }
    }

    renderSubscriptionInfo({title, info, pricing, subscription}) {
        return (
            <div>
              <CustomerNotificationCardHeader>
                <div css={subscription_info_header_style}>
                  {title}
                </div>
              </CustomerNotificationCardHeader>

              { subscription.override_customer_payment_description &&
                <>
                  <Row>
                    <Col>
                      {info}
                    </Col>
                  </Row>
                  <Separator variant="h20" />
                  <Row>
                    <Col md={4}>
                      {pricing}
                    </Col>
                    <Col>
                      <div css={override_customer_payment_description_style}>
                        
                        {subscription.override_customer_payment_description}
                      </div>
                    </Col>
                  </Row>
                </>
              }
              
              { ! subscription.override_customer_payment_description &&
                <>
                  {info}
                  <Separator variant="h20" />
                  {pricing}
                </>
              }
              <Separator variant="h20" />
            </div>
        )
    }

    renderSubscription(subscription) {

        const pricing = <>
                          <div>
                            <div css={info_row_style}>
                              <Trans>Subscription</Trans>: <span css={bold_style}>{get(subscription, ["product_name"])}</span>
                            </div>
                            { subscription.phone_number_number && 
                              <div css={info_row_style}>
                                <Trans>Phone number</Trans>: <span css={bold_style}><PhoneNumber phone_number={subscription.phone_number_number} /></span>
                              </div>
                            }
                            <div css={info_row_style}>
                              <Trans>Amount due</Trans>: <span css={bold_style}><CurrencyValue value={subscription.amount_owing_excluding_vat_euros} use_span={true} /></span>
                            </div>
                          </div>

                          <Separator variant="h30" />
                          
                          <div>
                            <BlueButton onClick={() => this.onMakePayment()}>
                              <Trans>Pay now</Trans>
                            </BlueButton>
                          </div>
                        </>
        
        return (
            <CustomerNotificationCard is_active={subscription.is_active}>

              { subscription.is_active &&
                this.renderSubscriptionInfo({title: <>
                                                      <Trans>Attention</Trans>: &nbsp;
                                                      { subscription.override_customer_payment_title || <Trans>Your subscription payment is due</Trans> }
                                                    </>,
                                             info: <>
                                                        { subscription.expires_at && 
                                                          <Trans
                                                            i18nKey="paysubscriptions__this_subscription_will_expire"
                                                            components={[<Timestamp css={bold_style} value={subscription.expires_at} format="date_longmonth" use_span={true} />]}
                                                            defaults="Your current subscription will expire on <0></0>."
                                                          />
                                                        }
                                                      </>,
                                             pricing,
                                             subscription
                                            })
              }

              { subscription.is_waiting_for_first_activation &&
                this.renderSubscriptionInfo({title: <>
                                                      { subscription.override_customer_payment_title || <Trans>Your subscription requires activation</Trans> }
                                                    </>,
                                             info: <>
                                                        <Trans
                                                          i18nKey="paysubscriptions__your_subscription_will_be_activated_as_soon"
                                                          components={[<Timestamp css={bold_style} value={subscription.first_activation_expires_at} format="date_longmonth" use_span={true} />]}
                                                          defaults="Please note that the subscription will be automatically cancelled if you don't pay before <0></0>."
                                                        />
                                                      </>,
                                             pricing,
                                             subscription
                                            })
              }
              
              { ! subscription.is_active && subscription.can_reactivate &&
                this.renderSubscriptionInfo({title: <>
                                                      <Trans>Attention</Trans>:&nbsp;
                                                      { subscription.override_customer_payment_title || <Trans>Your subscription is deactivated</Trans>}
                                                    </>,
                                             info: <>
                                                        <Trans
                                                          i18nKey="paysubscriptions__your_subscription_expired_on"
                                                          components={[<Timestamp value={subscription.expires_at} format="date_longmonth" use_span={true} />]}
                                                          defaults="Your subscription expired on <0></0> without your payment. Your phone number is now deactivated. You can still pay for the subscription and reactivate the number."
                                                        />
                                                        <Separator variant="h20" />
                                                        <div>
                                                          <span css={bold_style}>
                                                            <Trans
                                                              i18nKey="paysubscriptions__you_have_until_to_pay"
                                                              components={[<Timestamp value={subscription.irrevocably_expires_at} format="date_longmonth" use_span={true} />]}
                                                              defaults="You have until <0></0> to pay."
                                                            />
                                                          </span>
                                                          &nbsp; After that date the subscription will be cancelled and you will lose your number permanently.
                                                        </div>
                                                   </>,
                                             pricing,
                                             subscription
                                            })
              }

              
            </CustomerNotificationCard>
        )
    }

    renderModal(children) {
        return (
            <Modal show={true}
                   size="xl"
                   centered
                   aria-labelledby="contained-modal-title-vcenter"
                   onHide={this.onHideModal} >

              <Modal.Header closeButton>
              </Modal.Header>

              <Modal.Body>
                {children}
              </Modal.Body>
              
            </Modal>
        )
    }
    
    render() {
        const { is_ready, is_saving, subscription, subscriptions, is_loading, history, isModalVisible } = this.props
        const { navigating_to_mollie } = this.state
        return (
            <WrappingBusyMask is_loading={is_saving || is_loading || !is_ready || navigating_to_mollie}>
              { subscription &&
                <>
                  <MobileOnly>
                    { isModalVisible && this.renderModal(this.renderSubscription(subscription)) }
                  </MobileOnly>
                  <DesktopOnly>
                    {this.renderSubscription(subscription)}
                    <Separator variant="h40" />
                  </DesktopOnly>
                </>
              }
            </WrappingBusyMask>
        )
    }
    
}

function mapStateToProps(state, props) {
    const { amount } = props
    const subscriptions = customerSubscriptionPayableList.getVisibleObjects()
    const subscription = head(subscriptions)

    const isModalVisible = get(getModalProps(state, MODAL_NAME), "show", true) !== false
    return {
        amount,
        subscription,
        subscriptions,
        is_ready: customerSubscriptionPayableList.isReady(),
        is_loading: customerSubscriptionPayableList.isLoading(),
        is_saving: molliePaymentList.getIsSavingObject(),
        isModalVisible
    }
}

export default withRouter(connect(mapStateToProps)(PaySubscriptionsNotification))

const bold_style = css`
font-weight: 500;
`

const info_row_style = css`
margin-bottom: 5px;
`

const override_customer_payment_description_style = css`
white-space: pre-wrap;
`

const subscription_info_header_style = css`
font-weight: 500;
`
