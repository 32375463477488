/** @jsxImportSource @emotion/react */
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Trans, Translation } from 'react-i18next'
import { default_theme as theme } from '../emotion/theme'
import {
  first,
  startsWith,
  join,
  compact,
  head,
  filter,
  split,
  includes,
  map,
  get,
  size,
  pull,
} from 'lodash'
import Timestamp from './Timestamp'
import { confirmModal } from '../actions/ui'
import { topupList } from '../actions/topup'
import CurrencyValue from './CurrencyValue'
import CardInfoText from './layout/CardInfoText'
import CreditInformationCard from './CreditInformationCard'
import { WhiteButton } from './layout/WhiteButton'
import { BlueInlineLink } from './layout/BlueInlineLink'
import { BlueOutlineButton } from './layout/BlueOutlineButton'
import { MultipleEmailsSupportedInfoWidget } from './MultipleEmailsSupportedInfoWidget'
import { FreeMinutesDuration } from './FreeMinutesDuration'
import { Modal } from 'react-bootstrap'
import { Button } from 'react-bootstrap'
import { Error } from './layout/Error'
import ReactAudioPlayer from 'react-audio-player'
import JSONPretty from 'react-json-pretty'
import { VoiceNumberGreetingSelector } from './VoiceNumberGreetingSelector'
import { PhoneNumberRedirectServerSyncLog } from './PhoneNumberRedirectServerSyncLog'
import 'react-json-pretty/themes/monikai.css'
import { showSuccess, showError } from '../actions/Error'
import { FormikGeneralFormErrors } from './form/GeneralFormErrors'
import {
  FormikDropdownField,
  SemanticDropdownStandalone,
} from './form/Dropdown'
import { phoneNumberRedirectServerSyncLogList } from '../actions/phone_number_redirect_server_sync_log'
import { blockedNumberAutoCompleteList } from '../actions/blocked_number_auto_complete'
import { callForwardingCostCalculatorList } from '../actions/call_forwarding_cost_calculator'
import { StandaloneCheckboxField } from './form/CheckboxField'
import { FormikPhoneNumberInputField } from './form/PhoneNumberInputField'
import { FormikMultiplePhoneNumberField } from './form/MultiplePhoneNumberField'
import { FormikRadioGroupField } from './form/RadioGroupField'
import { NoodleHead, NoodleStem, NoodleTail } from './layout/Noodles'
import { DayPickerField } from './form/DayPicker'
import { FormikTimePicker } from './form/DatetimePicker'
import { FormikTimezonePicker } from './form/TimezonePicker'
import { CardHeader } from './layout/CardHeader'
import FormikOnChange from './form/FormikAutoSave'
import classNames from 'classnames'
import { jsx, css } from '@emotion/react'
import { Formik, Form, FieldArray, Field } from 'formik'
import { FormikInputField } from './form/InputField'
import { InlineInputField } from './form/InlineInputField'
import { InlineIcon } from './layout/InlineIcon'
import { InlineIconBar } from './layout/InlineIconBar'
import { BlueLinkButton } from './layout/BlueLinkButton'
import { BlueButton } from './layout/BlueButton'
import { GrayButton } from './layout/GrayButton'
import { handleSubmitResult } from '../actions/form'
import { answeringMessageList } from '../actions/answering_message'
import { Row, Col, Container } from 'react-bootstrap'
import { globalSettingsList } from '../actions/settings'
import { Separator } from './layout/Separator'
import { customerPhoneNumberForwardingList } from '../actions/customer_phone_number_forwarding'
import Card from './layout/Card'
import WrappingBusyMask from './WrappingBusyMask'
import CommonTable from './CommonTable'
import { PhoneNumber } from './PhoneNumber'
import { blockedNumberList } from '../actions/blocked_number'
import { subscriptionVoiceList } from '../actions/customer_subscription'
import { customerPhoneNumberList } from '../actions/customer_phone_number'
import { customerList } from '../actions/customer'
import {
  SHORT_DAY_NAMES,
  SHORT_DAY_INDEXES_IN_DISPLAY_ORDER,
} from '../actions/date_helpers'
import * as Yup from 'yup'

const blockedNumbersValidationSchema = Yup.object().shape({
  blocked_number: Yup.array().of(
    Yup.object({
      number: Yup.string().nullable().phoneNumber(),
    })
  ),
})

const phoneNumberOfficeHoursValidationSchema = Yup.object().shape({})

const phoneNumberNameValidationSchema = Yup.object().shape({
  phone_number_name: Yup.string().required('Required'),
})

const phoneNumberEmailValidationSchema = Yup.object().shape({
  phone_number_email: Yup.string()
    .email('Email must be valid')
    .required('Email is required'),
})

const forwardingPhoneNumbersValidationSchema = Yup.object().shape({})

const FORWARDING_LIST_TYPE_OPTIONS = [
  { value: 'phone_number', label: <Trans>Phone number</Trans> },
  { value: 'call_list', label: <Trans>Call list</Trans> },
]

const OFFICE_HOURS_VIEW_MODE_OPTIONS = [
  { value: 'in_office', label: <Trans>During work hours</Trans> },
  { value: 'out_of_office', label: <Trans>Outside work hours</Trans> },
]

const CALL_LIST_RING_STRATEGY_OPTIONS = [
  { value: 'hunt', label: <Trans>One at a time</Trans> },
  { value: 'ringall', label: <Trans>Simultaneous</Trans> },
]

const MAX_FORWARDING_DESTINATIONS = 5

const PHONE_NUMBER_VALIDATION_DEBOUNCE_MILLISECONDS = 300

export const VoiceNumberCallPlan = ({
  subscription_id,
  editing_as_customer,
  overrideAnsweringMessageList,
  ...props
}) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const is_loading = useSelector(() =>
    subscriptionVoiceList.isWaitingForObjectLoad()
  )
  const is_saving = useSelector(
    () =>
      customerPhoneNumberList.getIsSavingObject() ||
      customerPhoneNumberList.getIsSavingObject()
  )
  const subscription = useSelector(() =>
    subscriptionVoiceList.getObject(subscription_id)
  )
  const customer_phone_number = useSelector(() =>
    customerPhoneNumberList.getObject(
      get(subscription, ['customer_phone_number', 'id'])
    )
  )
  const phone_number_name_initial_values = customer_phone_number
  const customer = useSelector(() => customerList.getCustomer())
  const helpdesk_email = useSelector(() =>
    globalSettingsList.getSetting('helpdesk_email')
  )
  const helpdesk_phone = useSelector(() =>
    globalSettingsList.getSetting('helpdesk_phone')
  )
  const [greetingSelectorVisible, setGreetingSelectorVisible] = useState(false)
  const [savingMode, setSavingMode] = useState(null)
  const [backdoorShowRoutingJson, setBackdoorShowRoutingJson] = useState(false)
  const answeringMessages = useSelector(() =>
    answeringMessageList.getVisibleObjects()
  )
  const [forwardingPhoneNumberInfos, setForwardingPhoneNumberInfos] = useState(
    {}
  )
  const [forwardingPhoneNumberTimers, setForwardingPhoneNumberTimers] =
    useState({})
  const blockedNumbersForSubscription = useSelector(() =>
    customerPhoneNumberList.getBlockedNumbers({ customer_phone_number })
  )
  const allBlockedNumbers = useSelector(() =>
    blockedNumberList.getVisibleObjects()
  )
  const all_destinations = useSelector(() =>
    customerPhoneNumberList.getAllDestinations({ customer_phone_number })
  )
  const callForwardingCostsByNumber = useSelector(() =>
    callForwardingCostCalculatorList.getObjectsById()
  ) // this is just to trigger a render when the data arrives
  const office_hours_enabled = useSelector(() =>
    customerPhoneNumberList.getOfficeHoursEnabled({ customer_phone_number })
  )
  const [officeHoursViewMode, setOfficeHoursViewMode] = useState('in_office') // one of OFFICE_HOURS_VIEW_MODE_OPTIONS
  const activeClusterType = office_hours_enabled
    ? officeHoursViewMode
    : 'default'
  const forwardingListTypeOption = useSelector(() =>
    customerPhoneNumberList.getForwardingListTypeOption({
      customer_phone_number,
      cluster_type: activeClusterType,
    })
  )
  const [
    blockedNumberLookupOnDoneCallBack,
    setBlockedNumberLookupOnDoneCallBack,
  ] = useState()
  const blockedNumberAutoCompleteResults = useSelector(() =>
    blockedNumberAutoCompleteList.getResults()
  )

  useEffect(() => {
    dispatch(subscriptionVoiceList.ensureObjectLoaded(subscription_id))
    dispatch(globalSettingsList.ensureGlobalSettingsLoaded())
    dispatch(answeringMessageList.updatePaginationNumItemsPerPage(100))
    dispatch(answeringMessageList.updateListFilter({ customer: customer.id }))
    dispatch(answeringMessageList.fetchListIfNeeded())
    dispatch(blockedNumberList.updatePaginationNumItemsPerPage(1000))
    dispatch(blockedNumberList.fetchListIfNeeded())
  }, [])

  useEffect(() => {
    if (blockedNumberLookupOnDoneCallBack) {
      blockedNumberLookupOnDoneCallBack(blockedNumberAutoCompleteResults)
    }
  }, [JSON.stringify(blockedNumberAutoCompleteResults)])

  useEffect(() => {
    if (get(subscription, ['customer_phone_number', 'id'])) {
      dispatch(
        customerPhoneNumberList.ensureObjectLoaded(
          subscription.customer_phone_number.id
        )
      )
    }
  }, [get(subscription, 'customer_phone_number')])

  useEffect(() => {
    dispatch(
      callForwardingCostCalculatorList.fetchForNumbers({
        subscription_id: subscription_id,
        numbers: map(all_destinations, (x) => x.number),
      })
    )
  }, [JSON.stringify(all_destinations)])

  useEffect(() => {
    if (get(subscription, ['customer'])) {
      dispatch(customerList.ensureObjectLoaded(subscription.customer))
      dispatch(
        answeringMessageList.updateListFilter({
          customer: subscription.customer,
        })
      )
      dispatch(answeringMessageList.fetchListIfNeeded())
    }
  }, [get(subscription, 'customer')])

  useEffect(() => {
    if (!get(customer_phone_number, 'id')) {
      return
    }
    const call_forwarding_enabled = get(
      customer_phone_number,
      'call_forwarding_enabled'
    )
    const voice_mail_enabled = customerPhoneNumberList.getVoiceMailEnabled({
      customer_phone_number,
      cluster_type: activeClusterType,
    })

    if (!call_forwarding_enabled && !voice_mail_enabled) {
      // force voicemail if no call forwarding
      dispatch(onToggleVoiceMailEnabled)
    }
  }, [get(customer_phone_number, 'call_forwarding_enabled')])

  const getActiveClusterType = () => {
    return activeClusterType
  }

  const onBuyCredit = () => {
    topupList.startBuyMoreCredit({ history })
  }

  const onForwardingPhoneNumberBlur = (value, field_name, formik_props) => {
    const timers = Object.assign({}, forwardingPhoneNumberTimers)
    const timer = get(timers, field_name)
    if (timer) {
      window.clearTimeout(timer)
    }
    timers[field_name] = window.setTimeout(() => {
      dispatch(
        customerPhoneNumberForwardingList.checkIsValid({
          number: value,
          customer_subscription: subscription,
        })
      ).then((res) => {
        const newNumberForwardingInfos = Object.assign(
          {},
          forwardingPhoneNumberInfos
        )
        newNumberForwardingInfos[field_name] = res
        setForwardingPhoneNumberInfos(newNumberForwardingInfos)
      })
    }, PHONE_NUMBER_VALIDATION_DEBOUNCE_MILLISECONDS)
    setForwardingPhoneNumberTimers(timers)
  }

  const onForwardingPhoneNumberChanged = (value, field_name, formik_props) => {
    if (forwardingPhoneNumberInfos[field_name]) {
      const newNumberForwardingInfos = Object.assign(
        {},
        forwardingPhoneNumberInfos
      )
      newNumberForwardingInfos[field_name].valid = undefined
      setForwardingPhoneNumberInfos(newNumberForwardingInfos)
    }
  }

  const onSelectedGreeting = () => {
    setGreetingSelectorVisible(false)
  }

  const onSavePhoneNumberName = ({ values, formik_funcs, onSaved }) => {
    setSavingMode('onSavePhoneNumberName')
    commonOnSavePhoneNumber({ values, formik_funcs, onSaved })
  }

  const onSavePhoneNumber = ({ values, formik_funcs, onSaved }) => {
    setSavingMode('onSavePhoneNumber')
    const answering_message = values.answering_message
    if (answering_message) {
      customerPhoneNumberList.setActiveAnsweringMessage({
        customer_phone_number,
        cluster_type: activeClusterType,
        answering_message,
      })
    }
    customerPhoneNumberList.setActiveVoiceForwardingEmail({
      customer_phone_number,
      cluster_type: activeClusterType,
      forward_voicemail_to_email: get(values, 'forward_voicemail_to_email'),
    })
    customerPhoneNumberList.setActiveVoiceForwardingName({
      customer_phone_number,
      cluster_type: activeClusterType,
      forward_voicemail_to_name: get(values, 'forward_voicemail_to_name'),
    })
    commonOnSavePhoneNumber({ formik_funcs, onSaved })
  }

  const onSaveBlockedNumbers = ({ values, formik_funcs, onSaved }) => {
    const newBlockedNumbers = get(values, 'blocked_number')
    setSavingMode('onSaveBlockedNumbers')
    dispatch(
      customerPhoneNumberList.setBlockedNumbers({
        customer_phone_number,
        blocked_numbers: newBlockedNumbers,
      })
    )
    commonOnSavePhoneNumber({ formik_funcs, onSaved })
  }

  const onSaveOfficeHours = ({ values, formik_funcs, onSaved }) => {
    setSavingMode('onSaveOfficeHours')
    values.applicable_days = split(values.applicable_days, ',')
    customerPhoneNumberList.setOfficeHours({ customer_phone_number, ...values })
    commonOnSavePhoneNumber({ formik_funcs, onSaved })
  }

  const commonOnSavePhoneNumber = ({
    values,
    formik_funcs,
    onSaved,
    enableSuccessNotification,
  }) => {
    const on_ok = (json) => {
      if (enableSuccessNotification !== false) {
        showSuccess(
          <Trans>Saved</Trans>,
          <Trans>Your change may take a minute to take effect</Trans>
        )
      }
      if (onSaved) {
        onSaved()
      }
      dispatch(
        phoneNumberRedirectServerSyncLogList.reloadForSubscription({
          customer_subscription_id: subscription_id,
        })
      )
    }

    if (!values) {
      values = {
        id: customer_phone_number.id,
        routing_as_json: customer_phone_number.routing_as_json,
      }
    }
    values.id = subscription.customer_phone_number.id

    dispatch(customerPhoneNumberList.saveObject(values)).then((res) => {
      setSavingMode(null)
      handleSubmitResult({ res, formik_funcs, on_ok })
    })
  }

  const onSaveForwardingPhoneNumbers = ({ values, formik_funcs, onSaved }) => {
    if (!checkAreAllForwardingNumbersValid(values)) {
      return
    }
    setSavingMode('onSaveForwardingPhoneNumbers')

    customerPhoneNumberList.setForwardingPhoneNumbers({
      customer_phone_number,
      cluster_type: activeClusterType,
      ...values,
    })
    commonOnSavePhoneNumber({ formik_funcs, onSaved })
  }

  const checkAreAllForwardingNumbersValid = ({ values }) => {
    if (
      size(filter(forwardingPhoneNumberInfos, (x) => x.valid === false)) > 0
    ) {
      const text = <Trans>Some forwarding phone numbers are invalid.</Trans>
      dispatch(
        confirmModal({
          text: text,
          can_cancel: false,
          heading: <Trans>Validation error</Trans>,
          confirm_text: <Trans>Ok</Trans>,
          onConfirmed: () => {},
        })
      )
      return false
    } else {
      return true
    }
  }

  const onToggleCallForwardingEnabled = () => {
    setSavingMode('onToggleCallForwardingEnabled')

    const is_call_forwarding_enabled =
      customerPhoneNumberList.isCallForwardingEnabled({
        customer_phone_number,
        cluster_type: activeClusterType,
      })

    if (is_call_forwarding_enabled) {
      customerPhoneNumberList.disableSendCallTo({
        customer_phone_number,
        cluster_type: activeClusterType,
      })
    } else {
      customerPhoneNumberList.enableSendCallTo({
        customer_phone_number,
        cluster_type: activeClusterType,
      })
    }

    commonOnSavePhoneNumber({ customer_phone_number, formik_funcs: {} })
  }

  const onToggleVoiceMailEnabled = () => {
    const on_ok = (json) => {
      // showSuccess(<Trans>Saved</Trans>)
    }

    setSavingMode('onToggleVoiceMailEnabled')
    const voice_mail_enabled = customerPhoneNumberList.getVoiceMailEnabled({
      customer_phone_number,
      cluster_type: activeClusterType,
    })

    dispatch(
      customerPhoneNumberList.setVoiceMailEnabled({
        customer_phone_number: customer_phone_number,
        cluster_type: activeClusterType,
        enabled: !voice_mail_enabled,
      })
    ).then((res) => {
      setSavingMode(null)
      handleSubmitResult({ res, formik_funcs: null, on_ok })
    })
  }

  const onToggleOfficeHoursEnabled = () => {
    const on_ok = (json) => {
      // showSuccess(<Trans>Saved</Trans>)
    }

    setSavingMode('onToggleOfficeHoursEnabled')

    dispatch(
      customerPhoneNumberList.setOfficeHoursEnabled({
        customer_phone_number: customer_phone_number,
        enabled: !office_hours_enabled,
      })
    ).then((res) => {
      setSavingMode(null)
      handleSubmitResult({ res, formik_funcs: null, on_ok })
    })
  }

  const onRemoveForwardingPhoneNumber = (arrayHelpers, index) => {
    arrayHelpers.remove(index)
  }

  const onAddForwardingPhoneNumber = (arrayHelpers, formik_props) => {
    const new_index = size(get(formik_props.values, 'destinations'))
    arrayHelpers.insert(
      new_index,
      customerPhoneNumberList.getEmptyDestination({ customer_phone_number })
    )
  }

  const onChangeForwardingListType = (value) => {
    customerPhoneNumberList.setForwardingListTypeOption({
      customer_phone_number,
      cluster_type: activeClusterType,
      forwarding_list_type: value,
    })

    setSavingMode('onChangeForwardingListType')
    commonOnSavePhoneNumber({
      customer_phone_number,
      formik_funcs: {},
      enableSuccessNotification: false,
    })
  }

  const onUpgradeSubscription = () => {
    history.push(`/subscription_upgrade_voice/${subscription.id}`)
  }

  const destinationQualifiesForFreeMinutes = (destination) => {
    const number = get(destination, 'number')
    return (
      size(
        filter(
          subscription.prefixes_qualifying_for_free_forwarding_minutes,
          (prefix) => startsWith(number, prefix)
        )
      ) > 0
    )
  }

  const blockedNumberLookup = ({ partialPhoneNumber, onDone }) => {
    setBlockedNumberLookupOnDoneCallBack(() => onDone)
    dispatch(blockedNumberAutoCompleteList.lookupForNumber(partialPhoneNumber))
  }

  const renderTitle = () => {
    const phone_number_name = get(customer_phone_number, 'phone_number_name')
    const has_phone_number_name = Boolean(phone_number_name)

    return (
      <Translation>
        {(t) => (
          <>
            {/* <Row>
                                <Col>
                                <BlueLinkButton onClick={history.goBack}>
                                <InlineIcon icon_name="chevron-left" variant="blue" />
                                <Trans>Back</Trans>
                                </BlueLinkButton>
                                <Separator variant="h20" />
                                </Col>
                                </Row> */}
            <Row>
              <Col md={9}>
                <div css={title_style}>
                  <div css={title_style_label}>
                    <PhoneNumber
                      phone_number={subscription.phone_number_number}
                    />
                    <Separator variant="w10" />
                    -
                    <Separator variant="w3" />
                  </div>
                  <div css={title_style_value}>
                    <InlineInputField
                      startInEditingMode={
                        get(customer_phone_number, 'id') &&
                        !has_phone_number_name
                      }
                      readOnlyValue={
                        <div
                          css={
                            has_phone_number_name
                              ? valid_customer_phone_name_style
                              : invalid_customer_phone_name_style
                          }
                        >
                          {phone_number_name || t('Enter a name')}
                        </div>
                      }
                      formInitialValues={phone_number_name_initial_values}
                      onSave={onSavePhoneNumberName}
                      is_saving={savingMode === 'onSavePhoneNumberName'}
                      validationSchema={phoneNumberNameValidationSchema}
                      renderFormFields={(formik_props) => (
                        <FormikInputField
                          name="phone_number_name"
                          input_css={phone_number_name_edit_style}
                          formik_props={formik_props}
                          placeholder="Enter a name"
                        />
                      )}
                    />
                  </div>
                </div>
              </Col>
            </Row>
          </>
        )}
      </Translation>
    )
  }

  const renderSendCallToRow = () => {
    if (!get(customer_phone_number, 'id')) {
      return null
    }

    const destinations = customerPhoneNumberList.getEnabledDestinations({
      customer_phone_number,
      cluster_type: activeClusterType,
    })

    const is_call_forwarding_enabled =
      customerPhoneNumberList.isCallForwardingEnabled({
        customer_phone_number,
        cluster_type: activeClusterType,
      })
    const has_valid_forward_to_phone_number_info =
      !destinations[0]._default_auto_created
    const initialValues = {
      destinations,
      ring_strategy: customerPhoneNumberList.getRingStrategy({
        customer_phone_number,
        cluster_type: activeClusterType,
      }),
      caller_id_type: get(
        customerPhoneNumberList.getGlobalOptions({ customer_phone_number }),
        'caller_id_type',
        'caller'
      ),
    }

    return (
      <div
        css={[
          row_style,
          white_row_style,
          is_call_forwarding_enabled ? null : disabled_row_style,
        ]}
      >
        <div css={toggle_column_style}>
          <div css={toggle_checkbox_cell_style}>
            <StandaloneCheckboxField
              onChange={() => onToggleCallForwardingEnabled()}
              label=""
              is_saving={savingMode === 'onToggleCallForwardingEnabled'}
              variant="large"
              checked={is_call_forwarding_enabled}
            />
          </div>
        </div>
        <div css={value_column_style}>
          <div css={multivalue_col_style}>
            <InlineInputField
              startInEditingMode={
                is_call_forwarding_enabled &&
                !has_valid_forward_to_phone_number_info
              }
              formButtonStyle="buttons"
              readOnlyValue={
                <div css={readonly_row_style}>
                  {renderReadonlySendCallToRow(
                    is_call_forwarding_enabled,
                    destinations
                  )}
                </div>
              }
              renderRightSide={() =>
                renderReadonlySendCallToRowCredit(destinations)
              }
              formInitialValues={initialValues}
              canEdit={is_call_forwarding_enabled}
              onSave={onSaveForwardingPhoneNumbers}
              is_saving={savingMode === 'onSaveForwardingPhoneNumbers'}
              validationSchema={forwardingPhoneNumbersValidationSchema}
              renderFormFields={renderEditCallToFormFields}
            />
          </div>
        </div>
      </div>
    )
  }

  const renderReadonlySendCallToRowCredit = (destinations) => {
    const atLeastOneDestinationQualifiesForFreeMinutes =
      size(filter(destinations, destinationQualifiesForFreeMinutes)) > 0
    const atLeastOneDestinationDoesntQualifyForFreeMinutes =
      size(filter(destinations, destinationQualifiesForFreeMinutes)) <
      size(destinations)
    const credits_excluding_vat_euros = get(
      customer,
      'credits_excluding_vat_euros',
      null
    )
    const credits_style =
      credits_excluding_vat_euros > 0
        ? credit_button_available_style
        : credit_button_empty_style
    const show_topup =
      editing_as_customer &&
      (atLeastOneDestinationDoesntQualifyForFreeMinutes ||
        subscription.remaining_monthly_free_voice_forwarding_minutes <= 0) &&
      credits_excluding_vat_euros <= 0
    const show_credits_badge =
      atLeastOneDestinationDoesntQualifyForFreeMinutes ||
      subscription.remaining_monthly_free_voice_forwarding_minutes <= 0
    const is_call_forwarding_enabled =
      customerPhoneNumberList.isCallForwardingEnabled({
        customer_phone_number,
        cluster_type: activeClusterType,
      })

    if (!is_call_forwarding_enabled) {
      return null
    }

    return (
      <div css={buy_credit_row_style}>
        {show_topup && (
          <div css={no_free_minutes_warning_style}>
            <WhiteButton
              extra_css={buy_credit_button_style}
              onClick={onBuyCredit}
              auto_disable={false}
            >
              <Trans>Buy Credit</Trans>
            </WhiteButton>
          </div>
        )}

        <Separator variant="w10" />

        {show_credits_badge && (
          <Button
            variant="success"
            css={[credit_button_common_style, credits_style]}
            onClick={onBuyCredit}
            auto_disable={false}
          >
            <CurrencyValue value={credits_excluding_vat_euros} />
          </Button>
        )}

        {atLeastOneDestinationQualifiesForFreeMinutes && (
          <>
            <Separator variant="w10" />
            <FreeMinutesDuration
              container_style={free_minutes_style}
              minutes={
                subscription.remaining_monthly_free_voice_forwarding_minutes
              }
            />
          </>
        )}
      </div>
    )
  }

  const renderDestinationRate = ({ destination, rate_object_for_number }) => {
    const number = get(destination, 'number')
    const qualifies_for_free_minutes =
      destinationQualifiesForFreeMinutes(destination)
    const show_rate =
      !qualifies_for_free_minutes ||
      subscription.remaining_monthly_free_voice_forwarding_minutes <= 0
    rate_object_for_number =
      show_rate &&
      (rate_object_for_number ||
        callForwardingCostCalculatorList.getForNumber({
          subscription_id: subscription.id,
          number,
        }))
    const rate = get(
      rate_object_for_number,
      'cost_per_minute_excluding_vat_euros'
    )
    const number_is_valid = number && number !== '00'

    return (
      <>
        {subscription.monthly_num_free_voice_forwarding_minutes > 0 &&
          !qualifies_for_free_minutes &&
          number_is_valid && (
            <div css={readonly_number_info_text_style}>
              <Separator variant="w10" />
              <Trans>Free minutes do not apply</Trans>
            </div>
          )}

        {show_rate && rate && (
          <div css={readonly_number_info_text_style}>
            <Separator variant="w10" />
            <CurrencyValue value={rate} />/<Trans>min.</Trans>
          </div>
        )}
      </>
    )
  }

  const renderReadonlySendCallToRow = (
    is_call_forwarding_enabled,
    destinations
  ) => {
    return (
      <>
        <Trans>Send call to</Trans>
        <Separator variant="w10" />
        <SemanticDropdownStandalone
          options={FORWARDING_LIST_TYPE_OPTIONS}
          disabled={!is_call_forwarding_enabled}
          default_value={customerPhoneNumberList.getForwardingListTypeOption({
            customer_phone_number,
            cluster_type: activeClusterType,
          })}
          on_change={(value) => onChangeForwardingListType(value)}
          is_saving={savingMode === 'onChangeForwardingListType'}
          allowAdditions={false}
        />
        <Separator variant="w20" />
        <div css={single_forward_phone_number_readonly_style}>
          {is_call_forwarding_enabled && (
            <div css={call_forwarding_destinations_readonly_list_style}>
              {map(destinations, (destination, index) => {
                const number = get(destination, 'number')
                const number_is_valid = number && number !== '00'

                return (
                  <>
                    {index > 0 && <Separator variant="h10" />}
                    <div css={call_forwarding_destinations_readonly_row_style}>
                      <div
                        css={call_forwarding_destinations_readonly_item_style}
                      >
                        {number_is_valid && (
                          <PhoneNumber phone_number={number} />
                        )}
                        {renderDestinationRate({ destination })}
                      </div>
                    </div>
                  </>
                )
              })}
            </div>
          )}
        </div>
      </>
    )
  }

  const renderAdditionalBlockedPhoneNumberInfo = (phone_number) => {
    const blockedNumber = first(
      filter(
        allBlockedNumbers,
        (blockedNumber) => blockedNumber.blocked_number === phone_number.number
      )
    )
    if (!get(blockedNumber, 'most_recent_cdr')) {
      return null
    }
    return (
      <div css={most_recent_blocked_cdr_style}>
        &nbsp;&nbsp;&nbsp;
        <Trans>Last call</Trans>&nbsp;
        <Timestamp
          use_span={true}
          value={blockedNumber.most_recent_cdr.start_datetime}
          format="dateshort-time"
        />
      </div>
    )
  }

  const renderBlockedNumberFields = (formik_props) => {
    return (
      <div>
        <Trans>Block calls</Trans>
        <Separator variant="h10" />
        <FormikMultiplePhoneNumberField
          formik_props={formik_props}
          placeholder="Enter number to block"
          name="blocked_number"
          addButtonLabel="Block a number"
          autoCompleteLookup={blockedNumberLookup}
          renderAdditionalPhoneNumberInfo={
            renderAdditionalBlockedPhoneNumberInfo
          }
        />
      </div>
    )
  }

  const renderEditCallToFormFields = (formik_props) => {
    const call_sent_to_option =
      customerPhoneNumberList.getForwardingListTypeOption({
        customer_phone_number,
        cluster_type: activeClusterType,
      })

    return (
      <div>
        <Row>
          <Col css={multivalue_col_style}>
            <div css={inline_edit_form_label_style}>
              <Trans>Send call to</Trans>
            </div>
            <SemanticDropdownStandalone
              options={FORWARDING_LIST_TYPE_OPTIONS}
              default_value={forwardingListTypeOption}
              on_change={onChangeForwardingListType}
              allowAdditions={false}
            />
          </Col>
        </Row>

        <Separator variant="h30" />

        {call_sent_to_option === 'phone_number' &&
          renderEditCallToFormFieldsSinglePhoneNumber(formik_props)}
        {call_sent_to_option === 'call_list' &&
          renderEditCallToFormFieldsCallList(formik_props)}
      </div>
    )
  }

  const renderEditCallToFormFieldsSinglePhoneNumber = (formik_props) => {
    const caller_id_options = [
      { value: 'caller', label: <Trans>Caller's number</Trans> },
      {
        value: 'subscription_number',
        label: (
          <div css={my_number_readonly_style}>
            <Trans>My number</Trans>
            <Separator variant="w2" />
            (<PhoneNumber phone_number={get(customer_phone_number, 'number')} />
            )
          </div>
        ),
      },
    ]
    return (
      <div>
        <FieldArray
          name={'destinations'}
          render={(arrayHelpers) => (
            <>
              <Row>
                <Col css={multivalue_col_style}>
                  <div css={inline_edit_form_label_style}>
                    <Trans>Phone number</Trans>
                  </div>
                  <FormikPhoneNumberInputField
                    name="destinations.0.number"
                    formik_props={formik_props}
                    onBlur={(value) =>
                      onForwardingPhoneNumberBlur(
                        value,
                        'destinations.0.number',
                        formik_props
                      )
                    }
                    onChange={(value) =>
                      onForwardingPhoneNumberChanged(
                        value,
                        'destinations.0.number',
                        formik_props
                      )
                    }
                    onlyCountries={get(
                      subscription,
                      'available_country_codes_for_call_forwarding',
                      ['nl']
                    )}
                  />
                  {tryRenderPhoneNumberInfos('destinations.0.number')}
                </Col>
              </Row>

              <Separator variant="w30" />

              <Row>
                <Col css={multivalue_col_style}>
                  <div css={inline_edit_form_label_style}>
                    <Trans>Ring for</Trans>
                  </div>
                  <FormikInputField
                    name="destinations.0.ring_duration_seconds"
                    extra_css={ring_duration_seconds_input_style}
                    formik_props={formik_props}
                  />
                  <Separator variant="w10" />
                  <Trans>seconds</Trans>
                </Col>
              </Row>

              <Separator variant="h30" />

              <Row>
                <Col css={multivalue_col_style}>
                  <div css={inline_edit_form_label_style}>
                    <Trans>Caller ID shows</Trans>
                  </div>
                  <div css={caller_id_type_style}>
                    <FormikRadioGroupField
                      formik_props={formik_props}
                      auto_scale={true}
                      name="caller_id_type"
                      options={caller_id_options}
                    />
                  </div>
                </Col>
              </Row>
              <Separator variant="h20" />
            </>
          )}
        />
      </div>
    )
  }

  const tryRenderPhoneNumberInfos = (field_name) => {
    const info = get(forwardingPhoneNumberInfos, field_name, {})

    return (
      <>
        {info.valid === false && (
          <>
            <Separator variant="w20" />
            <Error>
              <Trans>You cannot forward to this number</Trans>
            </Error>
          </>
        )}
        {info.cost_per_minute_excluding_vat_euros && (
          <>
            <Separator variant="w20" />
            <div css={readonly_number_info_text_style}>
              <Separator variant="w10" />
              <CurrencyValue value={info.cost_per_minute_excluding_vat_euros} />
              /<Trans>min.</Trans>
            </div>
          </>
        )}
      </>
    )
  }

  const renderEditCallToFormFieldsCallList = (formik_props) => {
    const caller_id_options = [
      { value: 'caller', label: <Trans>Caller's number</Trans> },
      {
        value: 'subscription_number',
        label: (
          <div css={my_number_readonly_style}>
            <Trans>My number</Trans>
            <Separator variant="w2" />
            (<PhoneNumber phone_number={get(customer_phone_number, 'number')} />
            )
          </div>
        ),
      },
    ]
    return (
      <div>
        <FormikRadioGroupField
          label="Call"
          auto_scale={false}
          name="ring_strategy"
          formik_props={formik_props}
          options={CALL_LIST_RING_STRATEGY_OPTIONS}
        />

        <Separator variant="h20" />

        <FieldArray
          name={'destinations'}
          render={(arrayHelpers) => (
            <>
              {map(
                get(formik_props, ['values', 'destinations'], []),
                (destination, index) => {
                  const number = get(formik_props.values, [
                    'destinations',
                    index,
                    'number',
                  ])
                  const phone_number_input_field_name = `destinations.${index}.number`
                  return (
                    <div key={index}>
                      <div css={forwarding_phone_number_collapsed_style}>
                        <div
                          css={forwarding_phone_number_collapsed_label_style}
                        >
                          {index + 1}.
                          <Separator variant="w10" />
                          <FormikPhoneNumberInputField
                            name={phone_number_input_field_name}
                            formik_props={formik_props}
                            onBlur={(value) =>
                              onForwardingPhoneNumberBlur(
                                value,
                                phone_number_input_field_name,
                                formik_props
                              )
                            }
                            onChange={(value) =>
                              onForwardingPhoneNumberChanged(
                                value,
                                'destinations.0.number',
                                formik_props
                              )
                            }
                            onlyCountries={get(
                              subscription,
                              'available_country_codes_for_call_forwarding',
                              ['nl']
                            )}
                          />
                          {tryRenderPhoneNumberInfos(
                            phone_number_input_field_name
                          )}
                          <Separator variant="w20" />
                          <Trans>Ring for</Trans>
                          <Separator variant="w10" />
                          <FormikInputField
                            name={`destinations.${index}.ring_duration_seconds`}
                            extra_css={ring_duration_seconds_input_style}
                            formik_props={formik_props}
                          />
                          <Separator variant="w10" />
                          <Trans>seconds</Trans>
                        </div>

                        <div
                          css={forwarding_phone_number_collapsed_actions_style}
                        >
                          <InlineIcon
                            icon_name="remove"
                            onClick={() =>
                              onRemoveForwardingPhoneNumber(arrayHelpers, index)
                            }
                          />
                        </div>
                      </div>

                      <Separator variant="h10" />
                    </div>
                  )
                }
              )}

              {size(get(formik_props.values, 'destinations')) <
                MAX_FORWARDING_DESTINATIONS && (
                <BlueLinkButton
                  onClick={() =>
                    onAddForwardingPhoneNumber(arrayHelpers, formik_props)
                  }
                >
                  <InlineIcon icon_name="add_no_circle" variant="blue" />
                  <Trans
                    i18nKey="voice_number_call_plan__add_destination"
                    values={{ max: MAX_FORWARDING_DESTINATIONS }}
                    components={[<span>{MAX_FORWARDING_DESTINATIONS}</span>]}
                    defaults="Add destination (max <0>{{max}}</0>)"
                  />
                </BlueLinkButton>
              )}

              <Separator variant="h30" />

              <Row>
                <Col css={multivalue_col_style}>
                  <div css={inline_edit_form_label_style}>
                    <Trans>Caller ID shows</Trans>
                  </div>
                  <div css={caller_id_type_style}>
                    <FormikRadioGroupField
                      formik_props={formik_props}
                      auto_scale={true}
                      name="caller_id_type"
                      options={caller_id_options}
                    />
                  </div>
                </Col>
              </Row>
            </>
          )}
        />
        <Separator variant="20px" />
      </div>
    )
  }

  const renderGreetingSelector = (formik_props) => {
    return (
      <Modal
        show={true}
        size="xl"
        onHide={() => setGreetingSelectorVisible(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <Trans>Select a greeting</Trans>
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <VoiceNumberGreetingSelector
            formik_props={formik_props}
            field_name="answering_message"
            customer_phone_number={customer_phone_number}
            overrideAnsweringMessageList={overrideAnsweringMessageList}
            onSelected={onSelectedGreeting}
          />
        </Modal.Body>
      </Modal>
    )
  }

  const renderVoiceMailFields = (formik_props) => {
    const answeringMessage = answeringMessageList.getBestAnsweringMessageForId({
      customer_phone_number,
      answering_message_id: get(formik_props.values, [
        'answering_message',
        'id',
      ]),
    })
    return (
      <Translation>
        {(t) => (
          <div css={row_editing_fields_container_style}>
            <Row>
              <Col css={multivalue_col_style}>
                <Trans>Record voicemail and forward to</Trans>
              </Col>
            </Row>
            <Separator variant="h30" />

            <Row>
              <Col css={multivalue_col_style}>
                <div css={inline_edit_form_label_style}>
                  <Trans>Email address</Trans>
                  &nbsp;
                  <MultipleEmailsSupportedInfoWidget />
                </div>
                <FormikInputField
                  name="forward_voicemail_to_email"
                  formik_props={formik_props}
                  placeholder={t('Enter an email')}
                />
              </Col>
            </Row>

            <Separator variant="h20" />

            {false && (
              <Row>
                <Col css={multivalue_col_style}>
                  <div css={inline_edit_form_label_style}>
                    <Trans>Name</Trans>
                  </div>
                  <FormikInputField
                    name="forward_voicemail_to_name"
                    formik_props={formik_props}
                    placeholder={t('Enter a name')}
                  />
                </Col>
              </Row>
            )}

            <Row>
              <Col css={multivalue_col_style}>
                <div css={inline_edit_form_label_style}>
                  <Trans>Greeting</Trans>
                </div>

                <div css={selected_greeting_style}>
                  <Trans>
                    {get(
                      formik_props.values,
                      ['answering_message', 'name'],
                      'Default'
                    )}
                  </Trans>
                </div>

                {answeringMessage && (
                  <>
                    <Separator variant="w20" />
                    <ReactAudioPlayer
                      src={
                        answeringMessage.answering_message_file_info_details
                          .original_download_url
                      }
                      controls
                    />
                  </>
                )}

                <Separator variant="w20" />
              </Col>
            </Row>

            <Separator variant="h20" />

            <Row>
              <Col css={multivalue_col_style}>
                <div css={inline_edit_form_label_style}>&nbsp;</div>
                <GrayButton
                  onClick={() => setGreetingSelectorVisible(true)}
                  auto_disable={false}
                >
                  <Trans>Change greeting</Trans>
                </GrayButton>
              </Col>
            </Row>

            {greetingSelectorVisible && renderGreetingSelector(formik_props)}

            <Separator variant="h30" />
          </div>
        )}
      </Translation>
    )
  }

  const renderVoiceMailRow = () => {
    const call_forwarding_enabled = get(
      customer_phone_number,
      'call_forwarding_enabled'
    )
    const voice_mail_enabled = customerPhoneNumberList.getVoiceMailEnabled({
      customer_phone_number,
      cluster_type: activeClusterType,
    })

    const initialValues = Object.assign({}, customer_phone_number, {
      answering_message: customerPhoneNumberList.getActiveAnsweringMessage({
        customer_phone_number,
        cluster_type: activeClusterType,
      }),
      forward_voicemail_to_name:
        customerPhoneNumberList.getActiveVoiceForwardingName({
          customer_phone_number,
          cluster_type: activeClusterType,
        }) || '',
      forward_voicemail_to_email:
        customerPhoneNumberList.getActiveVoiceForwardingEmail({
          customer_phone_number,
          cluster_type: activeClusterType,
        }) || '',
    })

    return (
      <div
        css={[
          row_style,
          white_row_style,
          voice_mail_enabled ? null : disabled_row_style,
        ]}
      >
        <div css={toggle_column_style}>
          <div css={toggle_checkbox_cell_style}>
            {call_forwarding_enabled && (
              <StandaloneCheckboxField
                onChange={onToggleVoiceMailEnabled}
                label=""
                variant="large"
                is_saving={savingMode === 'onToggleVoiceMailEnabled'}
                checked={voice_mail_enabled}
              />
            )}
            {!call_forwarding_enabled && (
              <StandaloneCheckboxField
                label=""
                disabled={true}
                variant="large"
                checked={voice_mail_enabled}
              />
            )}
          </div>
        </div>
        <div css={value_column_style}>
          <InlineInputField
            startInEditingMode={
              get(customer_phone_number, 'id') &&
              size(get(customer_phone_number, 'phone_number_email')) === 0
            }
            formButtonStyle="buttons"
            canEdit={voice_mail_enabled}
            readOnlyValue={
              <div css={readonly_row_style}>
                <Trans>Record voicemail and forward to</Trans>
                <Separator variant="w10" />
                <div css={phone_number_email_value_style}>
                  {customerPhoneNumberList.getActiveVoiceForwardingEmail({
                    customer_phone_number,
                    cluster_type: activeClusterType,
                  }) || 'Enter an email'}
                  {false && (
                    <>
                      ,{' '}
                      {customerPhoneNumberList.getActiveVoiceForwardingName({
                        customer_phone_number,
                        cluster_type: activeClusterType,
                      }) || 'Enter an name'}
                    </>
                  )}
                </div>
                {get(
                  subscription,
                  'voicemail_rate_per_minute_excluding_vat_euros'
                ) > 0 && (
                  <>
                    <Separator variant="w10" />
                    <div css={readonly_number_info_text_style}>
                      <CurrencyValue
                        value={
                          subscription.voicemail_rate_per_minute_excluding_vat_euros
                        }
                      />
                      /<Trans>minute</Trans>
                    </div>
                  </>
                )}
                {!get(
                  subscription,
                  'voicemail_rate_per_minute_excluding_vat_euros'
                ) &&
                  get(
                    subscription,
                    'voicemail_start_rate_excluding_vat_euros'
                  ) > 0 && (
                    <>
                      <Separator variant="w10" />
                      <div css={readonly_number_info_text_style}>
                        <CurrencyValue
                          value={
                            subscription.voicemail_start_rate_excluding_vat_euros
                          }
                        />
                        /<Trans>voicemail</Trans>
                      </div>
                    </>
                  )}
              </div>
            }
            formInitialValues={initialValues}
            onSave={onSavePhoneNumber}
            is_saving={savingMode === 'onSavePhoneNumber'}
            validationSchema={phoneNumberEmailValidationSchema}
            renderFormFields={renderVoiceMailFields}
          />
        </div>
      </div>
    )
  }

  const renderOfficeHoursFormFields = (formik_props) => {
    const { start_time, end_time } = formik_props.values

    return (
      <div css={row_editing_fields_container_style}>
        <Row>
          <Col css={multivalue_col_style}>
            <Trans>Route call depending on work hours</Trans>
          </Col>
        </Row>

        <Separator variant="h40" />

        <Row css={row_with_wrap_style}>
          <Col css={office_hours_multi_value_col_style}>
            <div css={office_hours_label_col_style}>
              <Trans>Working days</Trans>
            </div>
            <div css={multivalue_col_style}>
              <DayPickerField
                field_name="applicable_days"
                formik_props={formik_props}
                manage_value_as_comma_separated_string={true}
              />
            </div>
          </Col>
        </Row>

        <Row>
          <Col css={office_hours_multi_value_col_style}>
            <div css={office_hours_label_col_style}>
              <Trans>Work hours</Trans>
            </div>
            <div>
              <FormikTimePicker name="start_time" formik_props={formik_props} />
            </div>
            <Separator variant="w10" />
            <div>
              <Trans>to</Trans>
            </div>
            <Separator variant="w10" />
            <div>
              <FormikTimePicker name="end_time" formik_props={formik_props} />
            </div>
            <Separator variant="w10" />
            <div>
              <FormikTimezonePicker
                name="timezone"
                formik_props={formik_props}
              />
            </div>
          </Col>
        </Row>
      </div>
    )
  }

  const renderReadonlyApplicableDayNames = (applicable_days) => {
    const num_days = size(applicable_days)
    let at_least_one_added = false

    const applicable_days_in_order = compact(
      map(SHORT_DAY_INDEXES_IN_DISPLAY_ORDER, (day_index) =>
        includes(applicable_days, day_index) ? day_index : null
      )
    )

    return (
      <>
        {num_days === 7 && <Trans>Any day</Trans>}
        {num_days === 0 && <Trans>Never</Trans>}

        {size(applicable_days_in_order) < 7 &&
          map(applicable_days_in_order, (applicable_day, index) => (
            <>
              {index > 0 && (
                <>
                  ,<Separator variant="w3" />
                </>
              )}
              <Trans>{SHORT_DAY_NAMES[applicable_day]}</Trans>
            </>
          ))}
      </>
    )
  }

  const renderBlockedNumbersRow = () => {
    const initialValues = { blocked_number: blockedNumbersForSubscription }
    const supports_blocked_numbers = get(
      subscription,
      'product_supports_blocked_numbers',
      false
    )

    return (
      <div css={[row_style, white_row_style]}>
        <div css={value_column_style}>
          <InlineInputField
            startInEditingMode={
              get(customer_phone_number, 'id') &&
              size(get(customer_phone_number, 'phone_number_email')) === 0
            }
            formButtonStyle="buttons"
            canEdit={supports_blocked_numbers}
            renderRightSide={() => {
              if (supports_blocked_numbers) {
                return null
              } else {
                return (
                  <>
                    <div css={upgrade_to_unlock_notification}>
                      <Trans i18nKey={'upgrade_to_unlock_feature'}>
                        Upgrade to a paid subscription to unlock this feature
                      </Trans>
                    </div>
                    <BlueOutlineButton onClick={onUpgradeSubscription}>
                      Upgrade
                    </BlueOutlineButton>
                  </>
                )
              }
            }}
            renderReadOnlyValue={({ startEditing }) => {
              return (
                <div css={readonly_row_style}>
                  {supports_blocked_numbers && (
                    <>
                      {size(blockedNumbersForSubscription) === 0 && (
                        <div>
                          <Trans>Block calls</Trans>
                          &nbsp;
                          <BlueInlineLink onClick={startEditing}>
                            <Trans>Add numbers to block</Trans>
                          </BlueInlineLink>
                        </div>
                      )}
                      {size(blockedNumbersForSubscription) > 0 && (
                        <div>
                          <Trans>Block calls from</Trans>
                          &nbsp;
                          <span css={readonly_blocked_numbers_list_style}>
                            {blockedNumbersForSubscription[0].number}
                            {size(blockedNumbersForSubscription) > 1 && (
                              <span>
                                &nbsp;+{size(blockedNumbersForSubscription) - 1}{' '}
                                <Trans>more</Trans>
                              </span>
                            )}
                          </span>
                        </div>
                      )}
                    </>
                  )}
                  {!supports_blocked_numbers && (
                    <div css={disabled_row_style}>
                      <Trans>Block calls</Trans>
                    </div>
                  )}
                </div>
              )
            }}
            formInitialValues={initialValues}
            onSave={onSaveBlockedNumbers}
            is_saving={savingMode === 'onSaveBlockedNumbers'}
            validationSchema={blockedNumbersValidationSchema}
            renderFormFields={renderBlockedNumberFields}
          />
        </div>
      </div>
    )
  }

  const renderOfficeHoursRow = () => {
    const { applicable_days, start_time, end_time, timezone } =
      customerPhoneNumberList.getOfficeHours({ customer_phone_number })
    const initial_values = {
      applicable_days: join(applicable_days, ','),
      start_time,
      end_time,
      timezone,
    }

    return (
      <div
        css={[
          row_style,
          white_row_style,
          office_hours_enabled ? null : disabled_row_style,
        ]}
      >
        <div css={toggle_column_style}>
          <div css={toggle_checkbox_cell_style}>
            <StandaloneCheckboxField
              onChange={onToggleOfficeHoursEnabled}
              label=""
              is_saving={savingMode === 'onToggleOfficeHoursEnabled'}
              variant="large"
              checked={office_hours_enabled}
            />
          </div>
        </div>
        <div css={value_column_style}>
          <div css={multivalue_col_style}>
            <InlineInputField
              startInEditingMode={false}
              formButtonStyle="buttons"
              canEdit={office_hours_enabled}
              readOnlyValue={
                <div css={readonly_row_style}>
                  <Trans>Route the call depending on work hours</Trans>
                  <Separator variant="w10" />

                  <div css={readonly_row_value_style}>
                    {renderReadonlyApplicableDayNames(applicable_days)}
                    <Separator variant="w5" />-<Separator variant="w5" />
                    {(!start_time || !end_time) && <Trans>Any time</Trans>}
                    {start_time && end_time && (
                      <>
                        {start_time}
                        <Separator variant="w5" />-<Separator variant="w5" />
                        {end_time}
                      </>
                    )}
                  </div>
                </div>
              }
              formInitialValues={initial_values}
              onSave={onSaveOfficeHours}
              is_saving={savingMode === 'onSaveOfficeHours'}
              validationSchema={phoneNumberOfficeHoursValidationSchema}
              renderFormFields={renderOfficeHoursFormFields}
            />
          </div>
        </div>
      </div>
    )
  }

  const renderOfficeHoursViewModeSelector = () => {
    return (
      <div css={office_hours_selector_container_style}>
        <SemanticDropdownStandalone
          options={OFFICE_HOURS_VIEW_MODE_OPTIONS}
          default_value={officeHoursViewMode}
          on_change={setOfficeHoursViewMode}
          allowAdditions={false}
        />
      </div>
    )
  }

  const renderBackdoorRoutingJson = () => {
    return (
      <div css={backdoor_routing_style}>
        <h2>Routing information, for internal use only</h2>

        <h3>Synchronisation to Asterisk</h3>
        <PhoneNumberRedirectServerSyncLog
          customer_subscription_id={subscription_id}
        />

        <h3>Routing json</h3>
        <JSONPretty data={customer_phone_number.routing_as_json} />

        <h3>
          Routing json (as seen by gate, this can be different from the intended
          routing based on things like credits limits)
        </h3>
        <JSONPretty data={customer_phone_number.routing_as_json_for_asterisk} />
      </div>
    )
  }

  const renderCallForwardingSections = () => {
    return (
      <>
        <h1>
          <Trans>Call Plan</Trans>
        </h1>

        <Separator variant="h30" />

        <div css={row_style}>
          <div css={toggle_column_style}>
            <NoodleHead />
          </div>
          <div css={value_column_style}>
            <h2 css={h2_style}>
              <Trans>When a call comes in</Trans>
            </h2>
          </div>
        </div>

        {get(window, ['LOCAL_SETTINGS', 'ENABLE_BLOCKED_NUMBERS'], false) && (
          <>
            {renderBlockedNumbersRow()}
            <div css={noodle_row_style}>
              <div css={noodle_column_style}>
                <Separator variant="h30">
                  <NoodleStem />
                </Separator>
              </div>
            </div>
          </>
        )}

        {renderOfficeHoursRow()}

        <div css={noodle_row_style}>
          <div css={noodle_column_style}>
            <Separator variant="h30">
              <NoodleStem />
            </Separator>
          </div>
        </div>

        {office_hours_enabled && (
          <div css={[inner_row_style, noodle_row_style]}>
            <div css={non_toggle_column_style}>
              <NoodleHead />
            </div>
            <div css={inner_row_value_column_style}>
              {renderOfficeHoursViewModeSelector()}
            </div>
          </div>
        )}

        {renderSendCallToRow()}

        <div css={noodle_row_style}>
          <div css={noodle_column_style}>
            <Separator variant="h30">
              <NoodleStem />
            </Separator>
          </div>
        </div>

        {renderVoiceMailRow()}

        <div css={noodle_row_style}>
          <div
            css={noodle_column_style}
            onDoubleClick={() =>
              setBackdoorShowRoutingJson(!backdoorShowRoutingJson)
            }
          >
            <Separator variant="h50">
              <NoodleTail />
            </Separator>
          </div>
        </div>

        {backdoorShowRoutingJson && renderBackdoorRoutingJson()}
      </>
    )
  }

  const renderUneditable = () => {
    return (
      <>
        <CardHeader title={<Trans>Under construction</Trans>} />

        <Card variant="white">
          <div css={sub_heading_style}>
            <Trans>Please note</Trans>
          </div>
          <Separator variant="h10" />

          <Trans
            i18nKey="voice_numbers_under_construction_p1"
            values={{
              helpdesk_email: helpdesk_email,
              helpdesk_phone: helpdesk_phone,
            }}
            components={[
              <a css={helpdesk_contact_style} href={`mailto:${helpdesk_email}`}>
                {helpdesk_email}
              </a>,
              <a css={helpdesk_contact_style} href={`tel:${helpdesk_phone}`}>
                {helpdesk_phone}
              </a>,
            ]}
          />
          <Separator variant="h10" />
          <Trans
            i18nKey="voice_numbers_under_construction_p2"
            values={{
              helpdesk_email: helpdesk_email,
              helpdesk_phone: helpdesk_phone,
            }}
            components={[
              <a css={helpdesk_contact_style} href={`mailto:${helpdesk_email}`}>
                {helpdesk_email}
              </a>,
              <a css={helpdesk_contact_style} href={`tel:${helpdesk_phone}`}>
                {helpdesk_phone}
              </a>,
            ]}
          />
          <Separator variant="h10" />
          <Trans
            i18nKey="voice_numbers_under_construction_p3"
            values={{
              helpdesk_email: helpdesk_email,
              helpdesk_phone: helpdesk_phone,
            }}
            components={[
              <a css={helpdesk_contact_style} href={`mailto:${helpdesk_email}`}>
                {helpdesk_email}
              </a>,
              <a css={helpdesk_contact_style} href={`tel:${helpdesk_phone}`}>
                {helpdesk_phone}
              </a>,
            ]}
          />
        </Card>
      </>
    )
  }

  return (
    <WrappingBusyMask is_loading={is_loading}>
      {renderTitle()}
      <Separator variant="h50" />
      <Row>
        <Col md={12}>
          {customerPhoneNumberList.isEditable({ customer_phone_number }) &&
            renderCallForwardingSections()}
          {!customerPhoneNumberList.isEditable({ customer_phone_number }) &&
            renderUneditable()}
        </Col>
      </Row>
    </WrappingBusyMask>
  )
}

const title_style = css`
  display: flex;
  flex-wrap: wrap;
  font-size: 28px;
  font-weight: bold;
  align-items: center;
`

const title_style_label = css`
  display: flex;
  align-items: center;
`

const title_style_value = css`
  flex-grow: 1;
  max-width: 600px;
`

const row_style = css`
  display: flex;
  flex-wrap: wrap;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 15px;
  width: 100%;
  margin-left: 0px;
  margin-right: 30px;
  align-items: center;
  min-height: 68px;
`

const inner_row_style = css`
  display: flex;
  flex-wrap: wrap;
  padding-left: 20px;
  padding-right: 20px;
  width: 100%;
  margin-left: 0px;
  margin-right: 30px;
  align-items: center;
`

const disabled_row_style = css`
  color: ${theme.colors.dark_grey};
`

const white_row_style = css`
  background-color: #fff;
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  align-items: center;
  line-height: 18px;
`

const h2_style = css`
  font-size: 20px;
`

const phone_number_email_value_style = css`
  font-weight: bold;
`

const single_forward_phone_number_readonly_style = css`
  font-weight: bold;
`

const multivalue_col_style = css`
  display: flex;
  align-items: center;
  min-width: 150px;
`

const narrow_multivalue_col_style = css`
  display: flex;
  align-items: center;
  width: 100%;
  margin-right: 30px;
`

const ring_duration_seconds_input_style = css`
  width: 50px;
  max-width: 50px;
`

const caller_id_type_style = css`
  width: 500px;
`

const my_number_readonly_style = css`
  display: flex;
`

const toggle_column_style = css`
  display: flex;
  margin-right: 15px;
  min-width: 40px;
  max-width: 40px;
  align-self: flex-start;
  margin-top: 8px;
  justify-content: center;
  width: 100%;
`

const non_toggle_column_style = css`
  display: flex;
  margin-right: 15px;
  min-width: 40px;
  max-width: 40px;
  align-self: flex-start;
  justify-content: center;
  width: 100%;
`

const value_column_style = css`
  padding-bottom: 15px;
  flex-grow: 1;
`

const inner_row_value_column_style = css`
  padding-bottom: 15px;
  flex-grow: 1;
  margin-top: -10px;
`

const valid_customer_phone_name_style = css``

const invalid_customer_phone_name_style = css`
  color: ${theme.colors.dark_grey};
`

const toggle_checkbox_cell_style = css`
  display: flex;
  flex-grow: 1;
`

const helpdesk_contact_style = css``

const sub_heading_style = css`
  font-weight: bold;
  font-size: 18px;
`

const noodle_column_style = css`
  width: 80px;
  max-width: 80px;
  min-width: 80px;
`

const noodle_row_style = css`
  margin-left: 0px;
  margin-right: 30px;
  width: 100%;
`

const phone_number_name_edit_style = css`
  width: 400px;
`

const readonly_row_style = css`
  display: flex;
  flex-wrap: wrap;
  align-items: baseline;
`

const readonly_row_value_style = css`
  font-weight: bold;
  display: flex;
`

const row_editing_fields_container_style = css`
  margin-top: 10px;
`

const row_with_multi_row_values_style = css`
  align-items: baseline;
  width: 100%;
`

const office_hours_label_col_style = css`
  min-width: 150px;
  width: 150px;
  margin-right: 30px;
`

const office_hours_multi_value_col_style = css`
  display: flex;
  margin-bottom: 20px;
  align-items: center;
  flex-wrap: wrap;
`

const noodle_with_dropdown_style = css`
  display: flex;
`

const office_hours_selector_container_style = css`
  width: 200px;
`

const selected_greeting_style = css`
  font-weight: bold;
`

const backdoor_routing_style = css`
  margin-top: 30px;
  border: 1px solid ${theme.colors.dark_middle_grey};
  padding-top: 30px;
  background-color: ${theme.colors.very_light_grey};
  padding: 10px;
`

const inline_edit_form_label_style = css`
  width: 150px;
`

const row_with_wrap_style = css`
  width: 100%;
`

const call_forwarding_destinations_readonly_list_style = css``

const call_forwarding_destinations_readonly_row_style = css`
  display: flex;
  justify-content: space-between;
`

const call_forwarding_destinations_readonly_item_style = css`
  display: flex;
`

const forwarding_phone_number_collapsed_style = css`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  background-color: ${theme.colors.nearly_white};
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 4px;
  padding-bottom: 4px;
`

const forwarding_phone_number_collapsed_label_style = css`
  display: flex;
  align-items: center;
`

const forwarding_phone_number_collapsed_actions_style = css`
  display: flex;
`

const forwarding_phone_number_expanded_style = css`
  background-color: ${theme.colors.nearly_white};
  margin-top: 20px;
  padding: 20px;
`

const readonly_number_info_text_style = css`
  color: ${theme.colors.dark_grey};
  font-weight: normal;
  display: flex;
`

const buy_credit_row_style = css`
  display: flex;
  align-items: center;
`

const credit_button_common_style = css`
  color: #fff;
  border-radius: 20px;
  font-size: 13px;
  padding: 3px 9px 4px 8px;
`

const credit_button_available_style = css`
  background-color: ${theme.colors.dark_green};
`

const credit_button_empty_style = css`
  background-color: ${theme.colors.primary_red};
  border-color: ${theme.colors.primary_red};

  &:hover {
    border-color: ${theme.colors.primary_red};
    background-color: ${theme.colors.primary_red};
  }
`

const free_minutes_style = css`
  font-size: 13px;
  padding: 3px 9px 5px 8px;
`

const no_free_minutes_warning_style = css`
  color: ${theme.colors.warning_red};
  display: flex;
  align-items: center;
`

const buy_credit_button_style = css`
  font-size: 14px;
  height: 32px;
`

const readonly_blocked_numbers_list_style = css`
  color: ${theme.colors.primary_red};
`

const most_recent_blocked_cdr_style = css`
  color: ${theme.colors.dark_grey};
`
const upgrade_to_unlock_notification = css`
  margin-right: 10px;
`
