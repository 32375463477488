/** @jsxImportSource @emotion/react */
import React, { useEffect, useState } from 'react'
import { jsx, css } from '@emotion/react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { map, get, size, forEach } from 'lodash'
import { Trans, Translation } from 'react-i18next'
import { Formik, Form } from 'formik'
import { FormikGeneralFormErrors } from './form/GeneralFormErrors'
import { molliePaymentList } from '../actions/mollie_payment'
import WrappingBusyMask from './WrappingBusyMask'

import Card from './layout/Card'
import { Row, Col } from 'react-bootstrap'
import { productList } from '../actions/product'
import { subscriptionList } from '../actions/customer_subscription'
import { handleSubmitResult } from '../actions/form'
import { productCostCalculator } from '../actions/product_cost_calculator'
import {
  NewSubscriptionCardForm,
  validationSchema,
} from './NewSubscriptionCardForm'
import { customerList } from '../actions/customer'
import * as Yup from 'yup'

export const NewSubscriptionCard = ({
  initial_product_short_id,
  product_filter,
  ...props
}) => {
  const dispatch = useDispatch()
  const [processingSubscription, setProcessingSubscription] = useState(false)
  const is_loading = !productList.isReady() || productList.isLoading()
  const is_saving =
    subscriptionList.getIsSavingObject() ||
    molliePaymentList.getIsSavingObject() ||
    processingSubscription
  const initial_values = { number_source: 'new' }
  const [paymentCostCalculationId, setPaymentCostCalculationId] = useState(null)
  const paymentCostCalculation =
    paymentCostCalculationId &&
    productCostCalculator.getObject(paymentCostCalculationId)
  const [selectedTopupId, setSelectedTopupId] = useState()
  const history = useHistory()

  useEffect(() => {
    if (!(product_filter.is_fax_related && product_filter.can_receive_voice)) {
      dispatch(productList.updateListFilter(product_filter))
    }
    dispatch(productList.fetchListIfNeeded())
  }, [])

  const handlePaySubscription = (subscription) => {
    let cost_including_vat_euros = get(
      paymentCostCalculation,
      'total_cost_euros_inc_vat',
      subscription.amount_owing_including_vat_euros
    )

    if (!cost_including_vat_euros) {
      history.push(`/subscription_confirmation/${subscription.id}`)
    } else {
      const on_ok = (mollie_record) => {
        if (mollie_record.checkout_url) {
          molliePaymentList.rememberPendingMolliePaymentId(mollie_record.id)
          window.location = mollie_record.checkout_url
        }
      }
      molliePaymentList.rememberAsReturnUrl(
        `/subscription_confirmation/${subscription.id}`
      )
      dispatch(molliePaymentList.startPaySubscription({ subscription })).then(
        (res) => handleSubmitResult({ res, on_ok })
      )
    }
  }

  const onCreateSubscription = (values, formik_funcs) => {
    if (selectedTopupId) {
      values['initial_topup'] = selectedTopupId
    }
    setProcessingSubscription(true)
    const on_ok = (json) => {
      dispatch(subscriptionList.invalidateList())
      dispatch(subscriptionList.fetchListIfNeeded())
      dispatch(customerList.invalidateCustomer())
      dispatch(customerList.ensureCustomerLoaded())
      handlePaySubscription(json)
    }
    return dispatch(subscriptionList.saveNewObject(values)).then((res) =>
      handleSubmitResult({ res, formik_funcs, on_ok })
    )
  }

  return (
    <WrappingBusyMask is_loading={is_loading || is_saving}>
      {!is_loading && (
        <>
          <Formik
            initialValues={initial_values}
            onSubmit={onCreateSubscription}
            enableReinitialize={true}
            validationSchema={validationSchema}
          >
            {(formik_props) => {
              return (
                <Form>
                  <FormikGeneralFormErrors
                    render={(msg) => (
                      <Row>
                        <Col>{msg}</Col>
                      </Row>
                    )}
                  />

                  <Card
                    variant="white_wide_and_top_padding"
                    with_padding_below={false}
                  >
                    <NewSubscriptionCardForm
                      formik_props={formik_props}
                      initial_product_short_id={initial_product_short_id}
                      onNewPaymentCostCalculationId={
                        setPaymentCostCalculationId
                      }
                      onNewSelectedTopupId={setSelectedTopupId}
                    />
                  </Card>
                </Form>
              )
            }}
          </Formik>
        </>
      )}
    </WrappingBusyMask>
  )
}
