/** @jsxImportSource @emotion/react */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { InlineIcon } from '../../components/layout/InlineIcon'
import { withRouter } from 'react-router-dom'
import { jsx, css } from '@emotion/react'
import { Link } from 'react-router-dom'
import { size, map, forEach, get, isEmpty } from 'lodash'
import FieldInfoText from '../../components/layout/FieldInfoText'
import { AdminCallForwardingCostOptions } from './AdminCallForwardingCostOptions'
import { Formik, Form, FieldArray, Field } from 'formik'
import { constructPostSignupUrl } from '../../actions/ui'
import Card from '../../components/layout/Card'
import { adminProductList } from '../actions/admin_product'
import { adminPhoneNumberPrefixSetList } from '../actions/admin_phone_number_prefix_set'
import { adminFaxCostStructureVariantList } from '../actions/admin_fax_cost_structure_variant'
import { adminCallForwardingCostStructureVariantList } from '../actions/admin_call_forwarding_cost_structure_variant'
import { AdminProductSupplementNumberTypes } from './AdminProductSupplementNumberTypes'
import { LanguageFlag } from '../../components/LanguageFlag'
import CardInfoText from '../../components/layout/CardInfoText'
import { Container, Row, Col, Button } from 'react-bootstrap'
import { FormikInputField } from '../../components/form/InputField'
import { FormikHtmlTextareaField } from '../../components/form/HtmlTextareaField'
import { MultilingualField } from '../../components/form/MultilingualField'
import FormToggleButton from '../../components/FormToggleButton'
import WrappingBusyMask from '../../components/WrappingBusyMask'
import { ButtonBar } from '../../components/layout/ButtonBar'
import { BlueButton } from '../../components/layout/BlueButton'
import { BlueLinkButton } from '../../components/layout/BlueLinkButton'
import { WhiteButton } from '../../components/layout/WhiteButton'
import { Separator } from '../../components/layout/Separator'
import * as Yup from 'yup'
import { default_theme as theme } from '../../emotion/theme'
import { FormikGeneralFormErrors } from '../../components/form/GeneralFormErrors'
import { FormikRadioGroupField } from '../../components/form/RadioGroupField'
import { FormikCheckboxField } from '../../components/form/CheckboxField'
import { Trans, Translation } from 'react-i18next'
import { FormikDropdownField } from '../../components/form/Dropdown'
import { LANGUAGES } from '../../actions/language'

const LEFT_WIDTH = 3
const RIGHT_WIDTH = 9

const yup_shape = {
    can_receive_faxes: Yup.boolean(),
    can_send_faxes: Yup.boolean(),
    name: Yup.string().required("Name is required")
}
// forEach(LANGUAGES, ({language_code, language_name}) => {
//     yup_shape[`customer_facing_description_${language_code}`] = Yup.string()
// })

const validationSchema = Yup.object().shape(yup_shape)

class AdminProductForm extends Component {
   
    componentDidMount() {
        const { dispatch, product_id } = this.props
        if (product_id) {
            dispatch(adminProductList.ensureObjectLoaded(product_id))
        }
        dispatch(adminPhoneNumberPrefixSetList.updatePaginationNumItemsPerPage(1000))
        dispatch(adminPhoneNumberPrefixSetList.fetchListIfNeeded())
        dispatch(adminFaxCostStructureVariantList.updatePaginationNumItemsPerPage(1000))
        dispatch(adminFaxCostStructureVariantList.fetchListIfNeeded())
        dispatch(adminCallForwardingCostStructureVariantList.fetchListIfNeeded())
        dispatch(adminCallForwardingCostStructureVariantList.updatePaginationNumItemsPerPage(1000))
    }

    renderExternalLinks = (formik_props) => {
        const { product } = this.props
        const creatable_by_customer = get(formik_props, ["values", "creatable_by_customer"], false)

        const is_incoming_product = get(formik_props, ["values", "can_receive_faxes"], false) ||
              get(formik_props, ["values", "can_receive_voice"], false)
        
        const is_fax_out_only = get(formik_props, ["values", "can_send_faxes"], false) && !is_incoming_product

        let link_pattern
        if ( creatable_by_customer ) {
            if ( product.can_receive_voice ) {
                link_pattern = `/subscription/voice/signup/${product.short_id}`
            } else if ( product.can_receive_faxes ) {
                link_pattern = `/subscription/fax/signup/${product.short_id}`
            } else {
                link_pattern = `/subscription/signup/${product.short_id}`
            }
        } else if ( is_fax_out_only ) {
            link_pattern = '/send_a_fax'
        } else {
            return null
        }
        
        return (
            <>
              <Row>
                <Col md={LEFT_WIDTH} css={section_name_style}>
                  <Trans>External link</Trans>
                </Col>
                <Col md={RIGHT_WIDTH}>
                  <CardInfoText>
                    These links can be used for quick product selection by customers from external sites:
                  </CardInfoText>
                  <div>
                    { map(LANGUAGES, function(language) { 
                        return (
                            <div css={signup_link_row_style}>
                              <LanguageFlag language_code={language.language_code} />
                              <Separator variant="h10" />
                              <div css={url_style}>
                                {constructPostSignupUrl(link_pattern, {language: language.language_code})}
                              </div>
                            </div>
                        )

                    })}
                  </div>

                </Col>
              </Row>
              <Separator variant="h50" />
            </>
        )
        
    }

    renderCreatabilityOptions = (formik_props) => {
        const creatable_by_customer = get(formik_props, ["values", "creatable_by_customer"], false)
        const { product } = this.props
        
        return (
            <Card variant="white_wide_padding">
              <Separator variant="h50" />

              <Row>
                <Col md={LEFT_WIDTH} css={section_name_style}>
                  Creatability
                </Col>
                <Col md={RIGHT_WIDTH}>
                  <FormikCheckboxField name="default_for_new_customers" label="New customers get this subscription automatically"
                                       info_text={
                                           <CardInfoText>
                                             If selected, default options will be chosen for the subscription, so it is only appropriate for certain types of subscriptions, notably free fax out.
                                           </CardInfoText>
                                       }
                  />
                  <Separator variant="h10" />
                </Col>
              </Row>
              
              <Separator variant="h20" />

              <Row>
                <Col md={LEFT_WIDTH} css={section_name_style}>
                </Col>
                <Col md={RIGHT_WIDTH}>
                  <FormikCheckboxField name="creatable_by_customer" label="Customers can see and create this subscription" />
                </Col>
              </Row>

              <Separator variant="h20" />

              <Row>
                <Col md={LEFT_WIDTH} css={section_name_style}>
                </Col>
                <Col md={RIGHT_WIDTH}>
                  <FormikCheckboxField name="recommended" label="This product is recommended, eg during upsell notifications" />
                </Col>
              </Row>

              <Separator variant="h20" />

              <Row>
                <Col md={LEFT_WIDTH} css={section_name_style}>
                </Col>
                <Col md={RIGHT_WIDTH}>
                  <FormikCheckboxField name="is_trial" label="This is a trial product"
                                       info_text={
                                           <>
                                           <CardInfoText>
                                             An upgrade path will be automatically added to a subscription in order to force the customer to upgrade to a paid subscription or have the subscription canceled. 
                                           </CardInfoText>
                                           { get(formik_props.values, "is_trial") === true &&
                                             <div css={trial_expires_after_num_days_style}>
                                               Must upgrade within &nbsp;
                                               <FormikInputField name="trial_expires_after_num_days"
                                                                       type="text"
                                                                       extra_css={trial_expires_after_num_days_input_style}
                                                                       placeholder="" />
                                             &nbsp; days
                                             </div>
                                           }
                                           </>
                                       }
                  />
                </Col>
              </Row>

              <Separator variant="h20" />

              { get(formik_props, ["values", "can_receive_voice"], false) &&
                <Row>
                  <Col md={LEFT_WIDTH} css={section_name_style}>
                  </Col>
                  <Col md={RIGHT_WIDTH}>
                    <FormikCheckboxField name="trigger_paid_upgrade_path" label="Triggers paid plan recommended upgrade path"
                                         info_text={
                                             <>
                                             <CardInfoText>
                                               An upgrade path will be automatically added to a subscription in order to recommend the customer to upgrade to a paid subscription.
                                               This differs from a trial in that the upgrade is not forced on the customer, and only applies to voice products.
                                             </CardInfoText>
                                             </>
                                         }
                    />
                  </Col>
                </Row>
              }

              
              <Separator variant="h50" />

              { this.renderExternalLinks(formik_props) }
              
              { creatable_by_customer &&
                <>
                <Row>
                  <Col md={LEFT_WIDTH} css={section_name_style}>
                    Customer facing product information
                  </Col>
                  <Col md={RIGHT_WIDTH}>
                    <Col md={RIGHT_WIDTH}>
                      <MultilingualField label="Name visible to customers"
                                         name="customer_facing_name"
                                         render_component={({name}) => <FormikInputField name={name} label="Name visible to customers"/>}
                      />
                    </Col>
                  </Col>
                </Row>
                <Separator variant="h50" />
                <Row>
                  <Col md={LEFT_WIDTH} css={section_name_style}>
                  </Col>
                  <Col md={RIGHT_WIDTH}>
                    <Col md={RIGHT_WIDTH}>
                      <MultilingualField label="Description visible to customers"
                                         name="customer_facing_description"
					 render_component={({name}) => <FormikHtmlTextareaField key={`${product ? product.id : 'new'}_${name}`}
												name={name}
												formik_props={formik_props}
												placeholder="Description visible to customers"/>}
                      />
                    </Col>
                  </Col>
                </Row>
                <Separator variant="h50" />
                </>
              }
            </Card>
        )
    }

    renderSupplementaryOptionsNumberType = (formik_props) => {
        const { product } = this.props
        return (
            <>
              { product.requires_a_phone_number &&
                <>
                  <Separator variant="h50" />
                  <AdminProductSupplementNumberTypes field_name="supplement_number_types"
                                                     formik_props={formik_props}
                                                     LEFT_WIDTH={LEFT_WIDTH}
                                                     RIGHT_WIDTH={RIGHT_WIDTH}  />
                </>
              }
            </>
        )
    }
    
    renderSupplementaryOptionsNumberPorting = (formik_props) => {
        const { product } = this.props
        const supports_number_porting = get(formik_props, ["values", "supports_number_porting"], false)
        return (
            <>
              { product.requires_a_phone_number &&
                <>
                  <Separator variant="h50" />
                  <Row>
                    <Col md={LEFT_WIDTH} css={section_name_style}>
                      Number portability
                    </Col>
                    <Col md={RIGHT_WIDTH}>
                      <CardInfoText>
                        If checked, the customer will see options related to number portabitility when creating subscriptions for this product.
                      </CardInfoText>
                      <Separator variant="h20" />
                      <FormikCheckboxField name="supports_number_porting" label="Numbers can be ported into this product" />
                    </Col>
                  </Row>
                  { supports_number_porting &&
                    <Row>
                      <Col md={LEFT_WIDTH} css={section_name_style}>
                      </Col>
                      <Col md={RIGHT_WIDTH}>
                        Setup cost for porting (exVAT)
                        <CardInfoText>
                          The regular setup cost for this product is NOT included if number porting is selected by the customer.
                        </CardInfoText>
                        <div css={run_on_form_line_style}>
                          <FormikInputField
                            name="number_porting_setup_price_excluding_vat_euros"
                            type="text"
                            placeholder="Euros"
                          />
                        </div>
                      </Col>
                    </Row>
                  }
                  <Separator variant="h50" />
                </>
              }
            </>
        )
    }

    renderMinimumTraffic(formik_props) {
        const { product } = this.props
        const able_to_have_paid_traffic = get(formik_props, ["values", "can_receive_faxes"], false) ||
                                          get(formik_props, ["values", "can_receive_voice"], false)

        if ( ! able_to_have_paid_traffic ) {
            return null
        }

        const minimum_minutes = get(formik_props, ["values", "minimum_paid_minutes_before_cancellation"], 0)
        
        return (
            <Card variant="white_wide_padding">
              <Separator variant="h50" />
              <Row>
                <Col md={LEFT_WIDTH} css={section_name_style}>
                  Minimum Traffic
                </Col>
                <Col md={RIGHT_WIDTH}>
                  <CardInfoText>
                    If set and larger than zero, then this subscription may be cancelled if less than this number of minutes of traffic is received.
                  </CardInfoText>
                  <Separator variant="h20" />

                  <FormikInputField
                      name="minimum_paid_minutes_before_cancellation"
                      type="text"
                      placeholder="Minutes"
                  />

                  { minimum_minutes > 0 && 
                    <>
                    <Separator variant="h50" />
                    <CardInfoText>
                      If less than {minimum_minutes} minutes of paid traffic occurs on this number, send a warning email after this many days
                    </CardInfoText>
                    <Separator variant="h20" />

                    <FormikInputField
                        name="warn_if_below_minimum_traffic_after_num_days"
                        type="text"
                        placeholder="Days"
                    />

                    <Separator variant="h50" />
                    <CardInfoText>
                      If less than {minimum_minutes} minutes of paid traffic occurs on this number, cancel the subscription (with an email) after this many days
                    </CardInfoText>
                    <Separator variant="h20" />

                    <FormikInputField
                        name="cancel_if_below_minimum_traffic_after_num_days"
                        type="text"
                        placeholder="Days"
                    />
                    
                    </>
                  }
                  
                </Col>
              </Row>
              <Separator variant="h50" />
            </Card>
        )
    }

    renderIncomingFaxOptions(formik_props) {
        return (
            <Card variant="white_wide_padding">
              <Row>
                <Col md={LEFT_WIDTH} css={section_name_style}>
                  Incoming fax options
                </Col>
                <Col md={RIGHT_WIDTH}>
                  Maxinum number of pages per fax
                  <FieldInfoText>
                    This field has no implemented logic yet, it is only visual on some pages
                  </FieldInfoText>
                  <FormikInputField name="max_num_pages_per_incoming_fax"
                                    type="text"
                                    placeholder="Max pages" />
                  <Separator variant="h20" />
                </Col>
              </Row>
            </Card>
        )
    }

    renderCostOptions(formik_props) {
        const { product, phone_number_prefix_set_options, fax_cost_structure_variant_options } = this.props
        const values = formik_props.values
        
        return (
            <Card variant="white_wide_padding">
              { (get(values, 'can_receive_faxes', false) || get(values, 'can_receive_voice', false)) &&
                <div>
                  <Row>
                    <Col md={LEFT_WIDTH} css={section_name_style}>
                      Numbers
                    </Col>
                    <Col md={RIGHT_WIDTH}>
                      Provision numbers from
                      <FormikDropdownField name="phone_number_prefix_set"
                                           empty_selection_label="No prefix set"
                                           placeholder="Select prefix set"
                                           formik_props={formik_props}
                                           options={phone_number_prefix_set_options} />
                    </Col>
                  </Row>
                  <Separator variant="h50" />
                </div>
              }

              { this.renderSupplementaryOptionsNumberType(formik_props) }

              { get(values, 'can_send_faxes', false) &&
                <div>
                  <Row>
                    <Col md={LEFT_WIDTH} css={section_name_style}>
                      Outgoing fax rates
                    </Col>
                    <Col md={RIGHT_WIDTH}>
                      <Row>
                        <Col md="6">
                          Fax cost table
                          <FormikDropdownField name="fax_cost_structure_variant"
                                               formik_props={formik_props}
                                               empty_selection_label="No fax rate"
                                               placeholder="Select outgoing fax rate table"
                                               options={fax_cost_structure_variant_options} />
                        </Col>
                        <Col md="6">
                          Minimum cost per fax (Euros, exVAT)
                          <FieldInfoText>
                            This cost applies for each combination of destination number and file, ie it maps to an api call to the fax sending server.
                          </FieldInfoText>
                          <FormikInputField
                              name="minimum_outgoing_fax_cost_excluding_vat_euros"
                              type="text"
                              placeholder="Euros"
                          />
                        </Col>
                      </Row>
                    </Col>
                  </Row>

                  <Separator variant="h50" />
                </div>
              }

              { get(values, 'can_receive_voice', false) &&
                <AdminCallForwardingCostOptions formik_props={formik_props} RIGHT_WIDTH={RIGHT_WIDTH} LEFT_WIDTH={LEFT_WIDTH} />
              }

              <Row>
                <Col md={LEFT_WIDTH} css={section_name_style}>
                  Subscription price
                </Col>
                <Col md={RIGHT_WIDTH}>
                  <Row>
                    <Col md={6}>
                      Monthly amount for monthly subscriptions (exVAT)
                      <div css={run_on_form_line_style}>
                        <FormikInputField
                            name="monthly_subscription_price_excluding_vat_euros"
                            type="text"
                            placeholder="Euros"
                        />
                      </div>
                    </Col>
                    <Col md={6}>
                      Annual amount for annual subscriptions (exVAT)
                      <div css={run_on_form_line_style}>
                        <FormikInputField
                            name="annual_subscription_price_excluding_vat_euros"
                            type="text"
                            placeholder="Euros"
                        />
                      </div>
                    </Col>
                  </Row>
                  <Separator variant="h20" />
                  <Row>
                    <Col md={4}>
                      Once-off setup fee for new subscriptions (exVAT)
                      <FormikInputField
                          name="setup_price_excluding_vat_euros"
                          type="text"
                          placeholder="Euros"
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>

              { this.renderSupplementaryOptionsNumberPorting(formik_props) }

            </Card>
        )
    }

    renderGeneralOptions(formik_props) {

        const { product } = this.props
        
        return (
            <Card variant="white_wide_padding">
              <FormikGeneralFormErrors />

              <Row>
                <Col md={LEFT_WIDTH} css={section_name_style}>
                  <Trans>Name</Trans>
                </Col>
                <Col md={RIGHT_WIDTH}>
                  <FormikInputField
                      name="name"
                      type="text"
                      placeholder="Enter a name for this product"
                  />

                  { get(product, "legacy_ref") && (
                        <CardInfoText>
                          This is a legacy product with code <i>{product.legacy_ref}</i>
                          <br/>
                          You can rename it, future legacy data syncs will continue to use this product even if you change its settings.
                        </CardInfoText>
                  )}
                  
                </Col>
              </Row>

              <Separator variant="h40" />

              <div>
                <Row>
                  <Col md={LEFT_WIDTH} css={section_name_style}>
                    Core Features
                  </Col>
                  
                  <Col md={RIGHT_WIDTH}>
                    <FormikCheckboxField name="can_receive_voice" label="Forward voice and receive voice messages" />
                    <FormikCheckboxField name="can_receive_faxes" label="Receive faxes" />
                    <FormikCheckboxField name="can_send_faxes" label="Send faxes" />
                  </Col>
                </Row>
                <Separator variant="h50" />
              </div>
              
              
            </Card>
        )
    }
    
    render() {
        const { onSave, onCancel, initial_values, onCancelProduct, error, is_busy, is_saving, product, product_id } = this.props

        return (
            <Formik initialValues={initial_values}
                    onSubmit={onSave}
                    enableReinitialize={true}
                    validationSchema={validationSchema}
                    ref={product_id}
            >
              {formik_props => {
                   const { values } = formik_props
                   return (
                       <Form>
                         <div css={ form_layout }>

                           <WrappingBusyMask is_loading={is_busy || is_saving}>

                             { this.renderGeneralOptions(formik_props) }
                             { this.renderCostOptions(formik_props) }
                             { this.renderCreatabilityOptions(formik_props) }

                             { get(formik_props, ['values', 'can_receive_faxes']) && this.renderIncomingFaxOptions() }
                             { this.renderMinimumTraffic(formik_props) }
                             
                             <Separator variant="h50" />

                             <Card variant="white_wide_padding">
                               <Row>
                                 <Col md={LEFT_WIDTH} css={section_name_style}>
                                   <div>&nbsp;</div>
                                 </Col>
                                 <Col md={RIGHT_WIDTH}>
                                   <ButtonBar>
                                     <BlueButton type="submit">
                                       Save
                                     </BlueButton>
                                     <WhiteButton onClick={onCancel}>
                                       Cancel
                                     </WhiteButton>
                                   </ButtonBar>
                                 </Col>
                               </Row>
                             </Card>

                           </WrappingBusyMask>

                         </div>
                       </Form>
                   )
              }}
            </Formik>
        )
    }
}

function mapStateToProps(state, props) {
    const { onSave, onCancel, product_id } = props
    const product = adminProductList.getObject(product_id)
    const phone_number_prefix_set_options = adminPhoneNumberPrefixSetList.getAsOptions()
    const fax_cost_structure_variant_options = adminFaxCostStructureVariantList.getAsOptions()
    const call_forwarding_cost_structure_variant_options = adminCallForwardingCostStructureVariantList.getAsOptions()

    const default_values = {name: "",
                            monthly_subscription_price_excluding_vat_euros: 0,
                            annual_subscription_price_excluding_vat_euros: 0,
                            setup_price_excluding_vat_euros: 0,
                            can_receive_faxes: true,
                            can_send_faxes: true,
                            is_trial: false,
                            trial_expires_after_num_days: 60}
    const initial_values = Object.assign({}, default_values, product || {})
    return {
        onSave,
        onCancel,
        product,
        phone_number_prefix_set_options,
        fax_cost_structure_variant_options,
        call_forwarding_cost_structure_variant_options,
        initial_values,
        is_busy: adminProductList.isLoading(),
        is_saving: adminProductList.getIsSavingObject()
    }
}
export default withRouter(connect(mapStateToProps)(AdminProductForm))

const form_layout = css`
width: 90%;
display: flex;
flex-direction: column;
align-items: center;
padding-top: 20px;
`

const field_container = css`
width: 100%;
display: flex;
flex-direction: column;
`

const error_container = css`
display: flex;
align-items: center;
min-height: 30px;
flex-direction: column;
padding-top: 20px;

p {
margin: 0;
padding: 0;
color: ${theme.colors.error};
}
`

const footer = css`
display: flex;
flex-direction: row;
text-align: center;
justify-content: space-between;
width: 100%;
height: 100px;
padding-top:20px;
`

const label = css`
color: ${theme.colors.secondary};
font-weight: 600;
`

const section_name_style = css`
font-weight: 500;
font-size: 18px;
`

const run_on_form_line_style = css`
display: flex;
`

const signup_link_row_style = css`
display: flex;
line-height: 35px;
`

const url_style = css`
font-size: 12px;
white-space: nowrap;
`

const trial_expires_after_num_days_style = css`
display: flex;
margin-top: 13px;
align-items: center;
`

const trial_expires_after_num_days_input_style = css`
width: 50px;
`
