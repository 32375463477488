/** @jsxImportSource @emotion/react */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { jsx, css } from '@emotion/react'
import { default_theme as theme } from '../../emotion/theme'
import { AdminQuickLink } from './AdminQuickLink'
import { withRouter } from 'react-router-dom'
import { Modal } from 'react-bootstrap'
import { Trans, Translation } from 'react-i18next'
import { includes, head, get, map, keys, filter, size, cloneDeep } from 'lodash'
import ReactTimeout from 'react-timeout'
import { AdminCallForwardingCostOptions } from './AdminCallForwardingCostOptions'
import { InlineIcon } from '../../components/layout/InlineIcon'
import { adminUserList } from '../actions/admin_user'
import { globalSettingsList } from '../../actions/settings'
import { confirmModal } from '../../actions/ui'
import { adminPhoneNumberPrefixSetList } from '../actions/admin_phone_number_prefix_set'
import { Formik, Form, FieldArray, Field } from 'formik'
import { Error } from '../../components/layout/Error'
import { adminUpgradePathList } from '../actions/admin_upgrade_path'
import { FormHintLabel } from '../../components/form/FormHintLabel'
import AdminSubscriptionPhoneNumberSelector from './AdminSubscriptionPhoneNumberSelector'
import { invalidateAllAdminCustomerSubscriptionManualReservationList } from '../actions/admin_customer_subscription_manual_reservation'
import Loading from '../../components/Loading'
import { Separator } from '../../components/layout/Separator'
import { SeparatorWithLine } from '../../components/layout/SeparatorWithLine'
import AdminMainLayout from './AdminMainLayout'
import AdminUserForm from './AdminUserForm'
import BusyMask from '../../components/BusyMask'
import Timestamp from '../../components/Timestamp'
import CurrencyValue from '../../components/CurrencyValue'
import BooleanValue from '../../components/BooleanValue'
import FieldInfoText from '../../components/layout/FieldInfoText'
import { PhoneNumber } from '../../components/PhoneNumber'
import { ButtonBar } from '../../components/layout/ButtonBar'
import { FormikInputField } from '../../components/form/InputField'
import { FormikTextarea } from '../../components/form/TextareaField'
import { FormikCheckboxField, FormikSimpleCheckboxField } from '../../components/form/CheckboxField'
import { TraditionalRadioGroupField } from '../../components/form/TraditionalRadioGroupField'
import { FormikRadioGroupField } from '../../components/form/RadioGroupField'
import { adminCustomerList } from '../actions/admin_customer'
import { adminNumberList } from '../actions/admin_number'
import { adminProductList } from '../actions/admin_product'
import { adminCustomerSubscriptionForCustomerList, invalidateAllAdminCustomerSubscriptionLists } from '../actions/admin_customer_subscription'
import { adminFaxCostStructureVariantList } from '../actions/admin_fax_cost_structure_variant'
import { adminCallForwardingCostStructureVariantList } from '../actions/admin_call_forwarding_cost_structure_variant'
import { adminSubscriptionStatusList } from '../actions/admin_subscription_status'
import { FormikDatePicker } from '../../components/form/DatetimePicker'
import { DropdownStandalone, FormikDropdownField } from '../../components/form/Dropdown'
import WrappingBusyMask from '../../components/WrappingBusyMask'
import Card from '../../components/layout/Card'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import CardParagraphHeading from '../../components/layout/CardParagraphHeading'
import Container from 'react-bootstrap/Container'
import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
import { Form as BootstrapForm } from 'react-bootstrap'
import * as Yup from 'yup'
import {showSuccess, showError} from '../../actions/Error'
import Button from 'react-bootstrap/Button'
import { RedButton } from '../../components/layout/RedButton'
import { BlueButton } from '../../components/layout/BlueButton'
import { WhiteButton } from '../../components/layout/WhiteButton'
import { FormikGeneralFormErrors } from '../../components/form/GeneralFormErrors'
import { handleSubmitResult } from '../../actions/form'
import { NEW_SUBSCRIPTION_ID } from './AdminCustomerSubscriptions'
import { countryList } from '../../actions/country'
import { adminNumberTypes } from '../actions/admin_dropdown_options'

const LEFT_WIDTH = 3
const RIGHT_WIDTH = 9

const BILLING_OPTIONS = [ {label: 'Billed monthly', value: 'monthly'},
                          {label: 'Billed yearly', value: 'annually'} ]

const UPGRADE_PATHS_SUPPORTING_UPGRADE_LINK = ['free_to_paid_upsell_fax', 'free_to_paid_upsell_voice', 'free_to_paid_upsell_voice_optional']

export const PAYMENT_TYPE_OPTIONS = [ {label: 'Pre-paid', value: 'prepaid'},
                                      {label: 'Post-paid', value: 'postpaid'}, ]

const validationSchema = Yup.object().shape({
    status: Yup.string().required("Required")
    //phone_number: Yup.string().required("Required"),
    //fax_cost_structure_variant: Yup.string().required("Required"),
    //billing_frequency: Yup.string().required("Required"),
    //cost_per_month_incluing_vat_euros: Yup.number().required("Required"),
    //setup_price_excluding_vat_euros: Yup.number().required("Required")
})

class AdminCustomerSubscription extends Component {

    constructor(props) {
        super(props)
        this.state = {wizard_page: 1,
                      selected_product: null,
                      creating_auto_payment_link: false,
                      auto_payment_url: null,
                      creating_subscription_upgrade_link: false,
                      subscription_upgrade_url: null,
                      isShowingUpgradeToPaidVoiceSubscription: false
        }
    }
    
    componentDidMount() {
        const { dispatch, customer_id, customer_subscription_id } = this.props
        const { wizard_page } = this.state
        dispatch(adminCustomerList.ensureObjectLoaded(customer_id))
        dispatch(adminCustomerSubscriptionForCustomerList.updateListFilter({customer_id: customer_id}))
        const is_new = customer_subscription_id === NEW_SUBSCRIPTION_ID

        if ( ! is_new ) {
            dispatch(adminCustomerSubscriptionForCustomerList.ensureObjectLoaded(customer_subscription_id))
            dispatch(adminFaxCostStructureVariantList.updatePaginationNumItemsPerPage(1000))
            dispatch(adminFaxCostStructureVariantList.fetchListIfNeeded())
            dispatch(adminCallForwardingCostStructureVariantList.updatePaginationNumItemsPerPage(1000))
            dispatch(adminCallForwardingCostStructureVariantList.fetchListIfNeeded())
            if ( wizard_page === 1 ) {
                this.setState({wizard_page: 2})
            }
        }

        dispatch(adminPhoneNumberPrefixSetList.fetchListIfNeeded())
        dispatch(adminSubscriptionStatusList.updatePaginationNumItemsPerPage(100))
        dispatch(adminSubscriptionStatusList.fetchListIfNeeded())
        dispatch(adminProductList.updatePaginationNumItemsPerPage(1000))
        dispatch(adminProductList.fetchListIfNeeded())
        dispatch(countryList.updatePaginationNumItemsPerPage(1000))
        dispatch(countryList.fetchListIfNeeded())
        dispatch(adminNumberTypes.fetchListIfNeeded())

        dispatch(adminUpgradePathList.updateListFilter({customer:customer_id}))
        dispatch(adminUpgradePathList.fetchListIfNeeded())
    }

    componentDidUpdate(prev_props) {
        const { dispatch, customer_id, customer_subscription_id, products, subscription_product,
                subscription_for_customer_list_filter,
                customer_subscription } = this.props
        const { wizard_page } = this.state
        let { selected_product } = this.state
        if ( subscription_for_customer_list_filter.customer_id != customer_id ) {
            dispatch(adminCustomerSubscriptionForCustomerList.updateListFilter({customer_id: customer_id}))
        }
        dispatch(adminCustomerList.ensureObjectLoaded(customer_id))
        if ( customer_subscription_id !== NEW_SUBSCRIPTION_ID ) {
            dispatch(adminCustomerSubscriptionForCustomerList.ensureObjectLoaded(customer_subscription_id))
        }
        dispatch(adminCustomerSubscriptionForCustomerList.fetchListIfNeeded())
        dispatch(adminProductList.fetchListIfNeeded())
        if ( customer_subscription && selected_product != customer_subscription.product ) {
            dispatch(adminProductList.ensureObjectLoaded(customer_subscription.product))
        }
        if ( ! selected_product && subscription_product && subscription_product.id ) {
            selected_product = subscription_product
            this.setState({selected_product: subscription_product})
        }
        if ( selected_product ) {
            dispatch(adminProductList.ensureObjectLoaded(customer_subscription.product))
        }

        if ( selected_product ) {
            dispatch(adminFaxCostStructureVariantList.fetchListIfNeeded())
            dispatch(adminCallForwardingCostStructureVariantList.fetchListIfNeeded())
        }
        dispatch(adminUpgradePathList.fetchListIfNeeded())
        
    }

    onCancel = () => {
        const { history, customer_id } = this.props
        history.push(`/admin/customer/${customer_id}/subscriptions`)
    }

    onSelectProduct = (product) => {
        this.setState({selected_product: product})
    }
    
    onGotoPage2 = () => {
        this.setState({wizard_page: 2})
    }

    onSave = (values, formik_funcs) => {
        const { history, onSubmit, dispatch, customer_id, customer_subscription_id } = this.props
        const { selected_product } = this.state
        const is_new = customer_subscription_id === NEW_SUBSCRIPTION_ID
        formik_funcs.setSubmitting(true)
        const on_ok = function(json) {
            showSuccess("Saved", "Subscription saved")
            dispatch(adminCustomerSubscriptionForCustomerList.invalidateList())
            dispatch(adminCustomerSubscriptionForCustomerList.fetchListIfNeeded())
            dispatch(invalidateAllAdminCustomerSubscriptionLists())
            dispatch(invalidateAllAdminCustomerSubscriptionManualReservationList())
            if ( values.phone_number ) {
                dispatch(adminNumberList.invalidateObject(values.phone_number))
            }
            history.push(`/admin/customer/${customer_id}/subscriptions/`)
        }
        if ( is_new ) {
            values.customer = customer_id
            values.product = selected_product.id
            return dispatch(adminCustomerSubscriptionForCustomerList.saveNewObject(values))
                .then((res) => handleSubmitResult({res, formik_funcs, on_ok}))
        } else {
            values.id = customer_subscription_id
            return dispatch(adminCustomerSubscriptionForCustomerList.saveObject(values))
                .then((res) => handleSubmitResult({res, formik_funcs, on_ok}))
        }
    }

    onChangeBillingFrequency = (value, formik_props) => {
        const { selected_product } = this.state
        if ( value === 'monthly' ) {
            formik_props.setFieldValue("base_cost_per_period_excluding_vat_euros", get(selected_product, "monthly_subscription_price_excluding_vat_euros"))
        }
        else if ( value === 'annually' ) {
            formik_props.setFieldValue("base_cost_per_period_excluding_vat_euros", get(selected_product, "annual_subscription_price_excluding_vat_euros"))
        }
    }

    onRequestCancel = () => {
        const { dispatch, customer_subscription_id } = this.props

        let send_email_to_customer = false
        const onToggleSendEmailToCustomer = (evt, new_value) => {
            send_email_to_customer = evt.target.checked
        }
        const text = (
            <div>
              <div>
                Are you sure you want to cancel this subscription once it expires?
              </div>
              <SeparatorWithLine variant="h10" />
              <div>
                <label>
                  Send cancellation email to customer &nbsp;
                  <input type="checkbox"
                         value={send_email_to_customer}
                         onChange={onToggleSendEmailToCustomer}
                  />
                </label>
                <br/>
                <FormHintLabel>(even if this email option not ticked, an email will still be sent to the customer once the expiry date is reached)</FormHintLabel>
              </div>
            </div>
        )

        const on_ok = () => {
            dispatch(adminCustomerSubscriptionForCustomerList.invalidateObject(customer_subscription_id))
            dispatch(adminCustomerSubscriptionForCustomerList.ensureObjectLoaded(customer_subscription_id))
            showSuccess("Subscription cancelled", "Subscription cancellation pending")
        }
        
        const onConfirmed = () => {
            dispatch(adminCustomerSubscriptionForCustomerList.requestCancel({subscription_id: customer_subscription_id,
                                                                  send_email_to_customer: send_email_to_customer}))
                .then((res) => handleSubmitResult({res, formik_funcs: {}, on_ok: on_ok}))
        }
        dispatch(confirmModal({text:text, onConfirmed: onConfirmed}))
    }

    onCancelImmediately = (subscription) => {
        const { dispatch, customer_subscription_id } = this.props
        let send_email_to_customer = false
        const onToggleSendEmailToCustomer = (evt) => {
            send_email_to_customer = evt.target.checked
        }
        const text = (
            <div>
              <div>
                Are you sure you want to cancel this subscription immediately?
              </div>
              <SeparatorWithLine variant="h10" />
              <div>
                <label>
                  Send cancellation email to customer &nbsp;
                  <input type="checkbox"
                         value={send_email_to_customer}
                         onChange={onToggleSendEmailToCustomer}
                  />
                </label>
                <br/>
              </div>
            </div>
        )

        const on_ok = () => {
            dispatch(adminCustomerSubscriptionForCustomerList.invalidateObject(customer_subscription_id))
            dispatch(adminCustomerSubscriptionForCustomerList.ensureObjectLoaded(customer_subscription_id))
            showSuccess("Subscription cancelled", "Subscription cancelled")
        }
        
        const onConfirmed = () => {
            dispatch(adminCustomerSubscriptionForCustomerList.cancelImmediately({subscription_id: customer_subscription_id,
                                                                      send_email_to_customer: send_email_to_customer}))
                .then((res) => handleSubmitResult({res, formik_funcs: {}, on_ok: on_ok}))
        }
        dispatch(confirmModal({text:text, onConfirmed: onConfirmed}))
    }

    onUpgradeToPaidFaxSubscription = (subscription) => {
        const { dispatch, customer_subscription_id } = this.props
        let send_email_to_customer = true
        const onToggleSendEmailToCustomer = (evt) => {
            send_email_to_customer = evt.target.checked
        }
        const text = (
            <div>
              <div>
                Are you sure you want to force this subscription to be upgraded to a paid fax subscription in the next 30 days or be cancelled?
              </div>
              <SeparatorWithLine variant="h10" />
              <div>
                <label>
                  Send 'Free to paid fax' email to customer &nbsp;
                  <input type="checkbox"
                         value={send_email_to_customer}
                         onChange={onToggleSendEmailToCustomer}
                  />
                </label>
                <br/>
              </div>
            </div>
        )

        const on_ok = () => {
            dispatch(adminCustomerSubscriptionForCustomerList.invalidateObject(customer_subscription_id))
            dispatch(adminCustomerSubscriptionForCustomerList.ensureObjectLoaded(customer_subscription_id))

            dispatch(adminUpgradePathList.invalidateList(customer_subscription_id))
            dispatch(adminUpgradePathList.fetchListIfNeeded())
            
            showSuccess("Upgrade", "Subscription upgrade requested")
        }
        
        const onConfirmed = () => {
            dispatch(adminUpgradePathList.forceFreeToPaidUpsellFaxUpgrade({subscription_id:customer_subscription_id,
                                                                           send_email_to_customer: send_email_to_customer}))
                .then((res) => handleSubmitResult({res, formik_funcs: {}, on_ok: on_ok}))
        }
        dispatch(confirmModal({text:text, onConfirmed: onConfirmed}))
    }

    onUpgradeToPaidVoiceSubscription = (subscription) => {
        const { dispatch, customer_subscription_id } = this.props

        this.setState({isShowingUpgradeToPaidVoiceSubscription: true})
    }

    renderUpgradeToPaidVoiceSubscription = () => {
        const { dispatch, customer_subscription_id } = this.props
        const on_ok = () => {
            dispatch(adminCustomerSubscriptionForCustomerList.invalidateObject(customer_subscription_id))
            dispatch(adminCustomerSubscriptionForCustomerList.ensureObjectLoaded(customer_subscription_id))

            dispatch(adminUpgradePathList.invalidateList(customer_subscription_id))
            dispatch(adminUpgradePathList.fetchListIfNeeded())
            
            showSuccess("Upgrade", "Subscription upgrade requested")
            onClose()
        }

        const onConfirmed = (values) => {
            dispatch(adminUpgradePathList.forceFreeToPaidUpsellVoiceUpgrade({subscription_id:customer_subscription_id,
                                                                             send_email_to_customer: values.send_email_to_customer}))
                .then((res) => handleSubmitResult({res, formik_funcs: {}, on_ok: on_ok}))
        }

        const onClose = () => {
            this.setState({isShowingUpgradeToPaidVoiceSubscription: false})
        }
        
        return (

            <Modal show={true} onHide={onClose} >

              <Modal.Header closeButton={true}>
                <Modal.Title>
                  <Trans>Confirm upgrade</Trans>
                </Modal.Title>
              </Modal.Header>

              <Formik initialValues={{send_email_to_customer: true}}
                      enableReinitialize={true}
                      onSubmit={onConfirmed}>
                {formik_props => {
                  const { values } = formik_props
                  return (
                      <Form>
                        <Modal.Body>

                          <div>
                            Are you sure you want to force this subscription to be upgraded to a paid voice subscription in the next 30 days or be cancelled?
                          </div>
                          <SeparatorWithLine variant="h10" />
                          <div>
                            <label>
                              <FormikCheckboxField name="send_email_to_customer" label="Send 'Free to paid voice' email to customer" />
                            </label>
                            <br/>
                          </div>
                        </Modal.Body>

                        <Modal.Footer>

                          <ButtonBar>
                            <Button variant="danger" onClick={onClose}>
                              <Trans>Cancel</Trans>
                            </Button>

                            <Button type="submit" variant="success">
                              <Trans>Submit</Trans>
                            </Button>

                          </ButtonBar>
                        </Modal.Footer>
                      </Form>
                  )}}
                </Formik>
            </Modal>
        )
    }
    
    onUpgradeToPaidVoiceOptionalSubscription = (subscription) => {
        const { dispatch, customer_subscription_id } = this.props
        let send_email_to_customer = true
        const onToggleSendEmailToCustomer = (evt) => {
            send_email_to_customer = evt.target.checked
        }
        const text = (
            <div>
              <div>
                Are you sure you want to force this customer to be notified about an optional paid voice subscription upgrade?
              </div>

              <div>
                (An email will be sent to the customer)
              </div>
              
            </div>
        )

        const on_ok = () => {
            dispatch(adminCustomerSubscriptionForCustomerList.invalidateObject(customer_subscription_id))
            dispatch(adminCustomerSubscriptionForCustomerList.ensureObjectLoaded(customer_subscription_id))

            dispatch(adminUpgradePathList.invalidateList(customer_subscription_id))
            dispatch(adminUpgradePathList.fetchListIfNeeded())
            
            showSuccess("Upgrade", "Subscription upgrade requested")
        }
        
        const onConfirmed = () => {
            dispatch(adminUpgradePathList.forceFreeToPaidUpsellVoiceOptionalUpgrade({subscription_id:customer_subscription_id}))
                .then((res) => handleSubmitResult({res, formik_funcs: {}, on_ok: on_ok}))
        }
        dispatch(confirmModal({text:text, onConfirmed: onConfirmed}))
    }    

    onShowCreatingAutoPaymentLink = () => {
        this.setState({creating_auto_payment_link: true, auto_payment_url: null})
    }

    onHideCreatingAutoPaymentLink = () => {
        this.setState({creating_auto_payment_link: false})
    }

    onShowCreatingSubscriptionUpgradeLink = () => {
        this.setState({creating_subscription_upgrade_link: true, subscription_upgrade_url: null})
    }

    onHideCreatingSubscriptionUpgradeLink = () => {
        this.setState({creating_subscription_upgrade_link: false})
    }

    onPaymentCostCalculated = (formik_props, paymentCostCalculation) => {
        formik_props.setFieldValue("supplement_number_types", get(paymentCostCalculation, "supplements"))
    }

    onCreateAutoPaymentUrl = () => {
        const { dispatch, customer_id } = this.props
        const that = this

        const on_ok = (json) => {
            if ( json.status !== 'success' ) {
                showError("Failed", "Failed to create an auto login url")
            } else {
                that.setState({auto_payment_url: json.auto_payment_url})
            }
        }
        
        return dispatch(adminCustomerList.createAutoPaymentUrl(customer_id)).then((res) => handleSubmitResult({res, on_ok}))
    }

    onCreateSubscriptionUpgradeUrl = () => {
        const { dispatch, customer_subscription_id } = this.props
        const that = this

        const on_ok = (json) => {
            if ( json.status !== 'success' ) {
                showError("Failed", "Failed to create an upgrade url")
            } else {
                that.setState({subscription_upgrade_url: json.subscription_upgrade_url})
            }
        }
        
        return dispatch(adminCustomerSubscriptionForCustomerList.createSubscriptionUpgradeUrl({subscription_id: customer_subscription_id}))
            .then((res) => handleSubmitResult({res, on_ok}))
    }

    renderAutoPaymentLinkModal = () => {
        const { long_lived_otp_expire_in_hours, customer } = this.props
        const { auto_payment_url } = this.state
        return (
            <Modal show={true}
                   size="xl"
                   onHide={this.onHideCreatingAutoPaymentLink}>

              <Modal.Header closeButton>
                <Modal.Title>
                  Auto payment link
                </Modal.Title>
              </Modal.Header>

              <Modal.Body>

                Clicking create now will make a new auto payment url for this customer.
                <br/>
                This url will expire in {long_lived_otp_expire_in_hours/24} days
                <br/>
                Existing unexpired payment urls will not be affected by this operation.
                <br/>
                <Error>
                  Be aware that this url will log the customer in, it should only be shared with the customer at {customer.preferred_contact_email}
                </Error>

                <Separator variant="h20" />
                
                { ! auto_payment_url &&
                  <BlueButton onClick={this.onCreateAutoPaymentUrl}>
                    Create
                  </BlueButton>
                }

                { auto_payment_url &&
                  <div>
                    <pre>
                      {auto_payment_url}
                    </pre>
                  </div>
                }
                
              </Modal.Body>
            </Modal>
        )
    }

    renderSubscriptionUpgradeLinkModal = () => {
        const { long_lived_otp_expire_in_hours, customer } = this.props
        const { subscription_upgrade_url } = this.state
        return (
            <Modal show={true}
                   size="xl"
                   onHide={this.onHideCreatingSubscriptionUpgradeLink}>

              <Modal.Header closeButton>
                <Modal.Title>
                  Subscription upgrade payment link
                </Modal.Title>
              </Modal.Header>

              <Modal.Body>

                Clicking create now will make a new subscription upgrade url for this subscription.
                <br/>
                This url will expire in {long_lived_otp_expire_in_hours/24} days
                <br/>
                Existing unexpired urls will not be affected by this operation.
                <br/>
                <Error>
                  Be aware that this url will log the customer in, it should only be shared with the customer at {customer.preferred_contact_email}
                </Error>

                <Separator variant="h20" />
                
                { ! subscription_upgrade_url &&
                  <BlueButton onClick={this.onCreateSubscriptionUpgradeUrl}>
                    Create
                  </BlueButton>
                }

                { subscription_upgrade_url &&
                  <div>
                    <pre>
                      {subscription_upgrade_url}
                    </pre>
                  </div>
                }
                
              </Modal.Body>
            </Modal>
        )
    }
    
    renderSubscriptionPage1(formik_props) {
        const { customer_subscription_id, product_options } = this.props
        const { selected_product } = this.state
        return (
            <div>
              <Separator variant="h10" />
              <h1>
                { customer_subscription_id === NEW_SUBSCRIPTION_ID && <span>Add a subscription</span> }
                { customer_subscription_id !== NEW_SUBSCRIPTION_ID && <span>Edit subscription</span> }
              </h1>

              <Card variant="white_wide_padding">
                <Row>
                  <Col md={LEFT_WIDTH} css={section_name_style}>
                    Subscription
                  </Col>
                  <Col md={RIGHT_WIDTH}>
                    <DropdownStandalone default_value={get(selected_product, "name", "Select a product")}
                                        options={product_options}
                                        variant="bootstrap"
                                        on_change={this.onSelectProduct}/>
                  </Col>
                </Row>

                <Separator variant="h30" />
                <Row>
                  <Col md={LEFT_WIDTH} css={section_name_style}>
                  </Col>
                  <Col md={RIGHT_WIDTH}>
                    <ButtonBar>
                      <BlueButton onClick={this.onGotoPage2} auto_disable={false}>Next</BlueButton>
                      <RedButton onClick={this.onCancel}>Cancel</RedButton>
                    </ButtonBar>
                  </Col>
                </Row>
              </Card>
            </div>
        )
    }

    renderUpgradePathActionButtons = () => {
        const { customer_subscription, upgradePaths } = this.props

        const upgrade_paths_for_sub = filter(upgradePaths, (up) => up.customer_subscription === customer_subscription.id)
        const free_to_paid_upsell_fax_upgrade_path = head(filter(upgrade_paths_for_sub, (up) => up.upgrade_type === 'free_to_paid_upsell_fax'))
        
        return (
            <>
              { customer_subscription.is_subscription_eligible_for_free_to_paid_fax_upgrade_path_by_admin &&
                <div css={upgrade_path_action_button_style}>
                  <BlueButton onClick={this.onUpgradeToPaidFaxSubscription}>
                    Create free-to-paid required fax upgrade path
                  </BlueButton>
                  <br/>
                </div>
              }
              { customer_subscription.is_subscription_eligible_for_free_to_paid_voice_optional_upgrade_path_by_admin &&
                <div css={upgrade_path_action_button_style}>
                  <BlueButton onClick={this.onUpgradeToPaidVoiceOptionalSubscription}>
                    Create free to paid voice optional upgrade path
                  </BlueButton>
                  <br/>
                </div>
              }
              { customer_subscription.is_subscription_eligible_for_free_to_paid_voice_upgrade_path_by_admin &&
                <div css={upgrade_path_action_button_style}>
                  <BlueButton onClick={this.onUpgradeToPaidVoiceSubscription}>
                    Create free to paid voice required upgrade path
                  </BlueButton>
                  <br/>
                </div>
              }
            </>
        )            
    }

    renderActionButtons() {
        const { customer_subscription } = this.props
        return (
            <>
              <div css={action_buttons_style}>
                <Separator variant="h20" />
                { customer_subscription.can_request_cancel && 
                  <BlueButton onClick={this.onRequestCancel}>
                    Cancel once subscription expires
                  </BlueButton>
                }
                <Separator variant="w10" />
                { customer_subscription.can_cancel && 
                  <BlueButton onClick={this.onCancelImmediately}>
                    Cancel immediately
                  </BlueButton>
                }

              </div>

              <div css={upgrade_path_action_buttons_style}>
                { this.renderUpgradePathActionButtons() }
              </div>
            </>
        )
    }

    renderUpgradePaths() {
        const { customer_subscription, upgradePaths } = this.props

        const upgrade_paths_for_sub = filter(upgradePaths, (up) => up.customer_subscription === customer_subscription.id)
        const free_to_paid_upsell_fax_upgrade_path = head(filter(upgrade_paths_for_sub, (up) =>
                                                                 includes(UPGRADE_PATHS_SUPPORTING_UPGRADE_LINK, up.upgrade_type)))
        
        return (
            <>
              { ! customer_subscription.is_subscription_eligible_for_free_to_paid_fax_upgrade_path_by_admin && free_to_paid_upsell_fax_upgrade_path &&
                <div>
                  <Separator variant="h20" />
                  An upgrade path has been applied to this subscription
                  <br/>
                  <WhiteButton onClick={this.onShowCreatingSubscriptionUpgradeLink}>
                    Get customer upgrade link
                  </WhiteButton>
                  </div>
              }
            </>
        )            
    }

    renderNumberSection(formik_props) {
        const that = this
        const { customer, customer_subscription } = this.props
        const { selected_product } = this.state
        return (
            <div>
              <Row>
                <Col md={LEFT_WIDTH} css={section_name_style}>
                  Number
                </Col>
                <Col md={RIGHT_WIDTH}>

                  <AdminSubscriptionPhoneNumberSelector customer={customer}
                                                        onPaymentCostCalculated={(payment_cost_calculation) => that.onPaymentCostCalculated(formik_props, payment_cost_calculation)}
                                                        customer_subscription={customer_subscription}
                                                        formik_props={formik_props}
                                                        product={selected_product}
                  />
                  
                </Col>
              </Row>
              <Separator variant="h50" />
            </div>
        )
    }

    renderForwardingFields(formik_props) {
        const { customer_subscription } = this.props
        return (
            <div>
              <Row>
                <Col md={LEFT_WIDTH} css={section_name_style}>
                  Forwarding
                </Col>
                <Col md={RIGHT_WIDTH}>

                  { get(customer_subscription, "customer_phone_number") &&
                    <>
                      
                      <FieldInfoText>
                        Email address for sending incoming faxes and voice messages.
                      </FieldInfoText>
                      <FormikInputField name="customer_phone_number.phone_number_email"
                                        placeholder="Email"
                                        formik_props={formik_props} />

                      <Separator variant="h20" />
                      
                      <FieldInfoText>
                        Name used in emails when sending incoming faxes and voice messages.
                      </FieldInfoText>
                      <FormikInputField name="customer_phone_number.phone_number_name"
                                        placeholder="Name"
                                        formik_props={formik_props} />

                    </>
                  }
                  { !get(customer_subscription, "customer_phone_number") &&
                    <>
                      <FieldInfoText>
                        These fields are only available once a phone number exists for this subscription.
                      </FieldInfoText>
                    </>
                  }
                </Col>
              </Row>
              <Separator variant="h50" />
            </div>
        )
    }

    renderCustomerDescriptionOverrideFields(formik_props) {
        const { customer } = this.props
        return (
            <div>
              <Row>
                <Col md={LEFT_WIDTH} css={section_name_style}>
                  Payment reminder overrides
                </Col>
                <Col md={RIGHT_WIDTH}>
                  <FieldInfoText>
                    If present, this text will appear in the customer's yellow reminder box
                    <br/>
                    Note, the customer's preferred language is <b>{customer.language_code}</b>
                    <br/>
                    The text will be automatically cleared after the next payment is made.
                  </FieldInfoText>
                  <FormikInputField name="override_customer_payment_title" placeholder="Payment reminder title" />
                  <Separator variant="h20" />
                  <FormikTextarea name="override_customer_payment_description" placeholder="Payment reminder description" />
                </Col>
              </Row>
              <Separator variant="h20" />
            </div>
        )
    }

    renderTrafficInfo() {
        const { customer_subscription } = this.props
        if ( ! customer_subscription.id ) {
            return null
        }
        return (
            <Row>
              <Col md={LEFT_WIDTH} css={section_name_style}>
                Traffic

                <Separator variant="h30" />

                
              </Col>

              <Col md={RIGHT_WIDTH}>

                { ! customer_subscription.product_minimum_paid_minutes_before_cancellation &&
                  <div>No minimum traffic requirement</div>
                }

                { customer_subscription.product_minimum_paid_minutes_before_cancellation > 0 && 
                  <div>
                    Must use {customer_subscription.product_minimum_paid_minutes_before_cancellation} paid minutes every {customer_subscription.product_cancel_if_below_minimum_traffic_after_num_days} days.
                    (with a warning after {customer_subscription.product_warn_if_below_minimum_traffic_after_num_days} days)
                    <br/>
                    The low traffic counter was reset on <Timestamp value={customer_subscription.low_traffic_counter_reset_at} use_span={true} format="date_longmonth" />.
                    <br/>
                    Since then there has been {customer_subscription.num_minutes_traffic_since_low_traffic_counter_reset_at*60} seconds of paid traffic,
                    with the last paid traffic {customer_subscription.num_days_since_last_traffic || "n/a"} days ago.
                    <br/>
                    (In the previous period, the customer used {customer_subscription.num_minutes_traffic_since_previous_low_traffic_counter_reset_at*60} seconds of paid traffic.)
                    <br/>
                    { customer_subscription.is_in_low_traffic_warning_zone &&
                      <div css={low_traffic_warning_style}>
                        <InlineIcon icon_name="status-error"/>
                        Low traffic this period, this subscription is in danger of being cancelled.
                      </div>
                    }
                  </div>
                }

              </Col>
            </Row>
        )
    }

    renderPriceFields(formik_props) {
        const billing_frequency = get(formik_props, ["values", "billing_frequency"])
        
        return (
            <Row>
              <Col md={LEFT_WIDTH} css={section_name_style}>
                Subscription price

                <Separator variant="h30" />
                
                <WhiteButton onClick={this.onShowCreatingAutoPaymentLink}>
                  Create auto payment link
                </WhiteButton>

              </Col>
              <Col md={RIGHT_WIDTH}>
                <Row>
                  <Col md="12">
                    <FormikRadioGroupField name="payment_type"
                                           formik_props={formik_props}
                                           options={PAYMENT_TYPE_OPTIONS} />

                    <Separator variant="h20" />
                  </Col>
                </Row>
                
                <Row>
                  <Col md="12">
                    <FormikRadioGroupField name="billing_frequency"
                                           onChange={(value) => this.onChangeBillingFrequency(value, formik_props)}
                                           formik_props={formik_props}
                                           options={BILLING_OPTIONS} />

                    <Separator variant="h20" />

                    { billing_frequency === "monthly" && 
                      <span>Base price per month (ex VAT, excluding supplements)</span>
                    }
                    { billing_frequency === "annually" && 
                      <span>Base price per year (ex VAT, excluding supplements)</span>
                    }
                    <FormikInputField name="base_cost_per_period_excluding_vat_euros" />

                    <Separator variant="h20" />

                    { this.renderSupplementFields(formik_props) }

                    Calculated cost per period (ex VAT and all supplements, updated after saving)
                    <CurrencyValue value={get(formik_props.values, "cost_per_period_excluding_vat_euros")} />

                    <Separator variant="h20" />
                    
                    Setup fee (exVAT)
                    <FormikInputField name="setup_price_excluding_vat_euros" />
                    
                  </Col>
                </Row>

              </Col>
            </Row>
        )
    }
    
    renderSupplementFields(formik_props) {
        const { countries_by_id, number_types_by_id } = this.props
        const field_name = "supplement_number_types"
        
        return (
            <FieldArray name={field_name}
                        render={arrayHelpers => (
                            <>
                              {map(get(formik_props.values, field_name), (supplement, index) => {
                                  return (
                                      <>
                                        <Row>
                                          <Col css={supplement_title_style}>
                                            Supplement (ex VAT): {supplement.label}
                                            <Separator variant="w10" />
                                            {get(countries_by_id, [supplement.country, "name"])}
                                            <Separator variant="w10" />
                                            {get(number_types_by_id, [supplement.number_type, "name"])}
                                          </Col>
                                        </Row>
                                        <Row>
                                          <Col>
                                            <FormikInputField name={`${field_name}.${index}.price_per_year_excluding_vat_euros`}
                                                              placeholder="Euros"
                                            />
                                          </Col>
                                        </Row>
                                        <Separator variant="h20" />
                                      </>
                                  )
                              })}
                            </>
                        )} 
            />
        )
    }

    renderSubscriptionPage2(formik_props) {
        const { customer_subscription_id, subscription_status_options,
                fax_cost_structure_variant_options,
                call_forwarding_cost_structure_variant_options,
                customer_subscription } = this.props
        const { selected_product } = this.state
        const is_new = customer_subscription_id === NEW_SUBSCRIPTION_ID
        const product = selected_product || {}
        const phone_number_is_relevant = product.can_receive_faxes || product.can_receive_voice
        const outgoing_fax_rate_is_relevant = product.can_send_faxes
        const call_forwarding_cost_structure_variant_is_relevant = product.can_receive_voice
        return (
            <div>
              <Card variant="white_wide_padding">
                <Separator variant="h10" />

                <Row>
                  <Col md={LEFT_WIDTH} css={section_name_style}>
                    Subscription
                  </Col>
                  <Col md={RIGHT_WIDTH}>

                    <Row>
                      <Col md={6}>
                        
                        <CardParagraphHeading>
                          <AdminQuickLink name="product" value={product.id} label={product.name} />
                        </CardParagraphHeading>
                        Version: v{product.version}

                        { ! selected_product &&
                          (
                              <Error>
                                Unexpected: There is no product for this subscription. This subscription is invalid and can't be resolved in this user interface.
                              </Error>
                          )
                        }

                        <Separator variant="h20" />
                        Status <FormHintLabel>(directly affects status and bypasses secondary affects, use action buttons for due-process)</FormHintLabel>
                        <FormikDropdownField name="status"
                                             formik_props={formik_props}
                                             options={subscription_status_options}
                                             placeholder="Select status" />

                        { ! is_new && this.renderActionButtons() }

                        { ! is_new && this.renderUpgradePaths() }
                        
                        { ! is_new && 
                          <div>
                            <Separator variant="h20" />
                            Created at <FormHintLabel>(affects the timeline for first payment)</FormHintLabel>
                            <FormikDatePicker name="created_at" formik_props={formik_props} />
                          </div>
                        }
                        { ! is_new && 
                          <div>
                            <Separator variant="h20" />
                            Expires at
                            <FormikDatePicker name="expires_at" formik_props={formik_props} can_clear={true} />
                          </div>
                        }

                        { customer_subscription.activated_at && 
                          <div>
                            <Separator variant="h20" />
                            Activated at <FormHintLabel>(set when the subscription first becomes active. Changing this value will not affect the subscription status, but will affect some calculations eg regarding low traffic)</FormHintLabel>
                            <FormikDatePicker name="activated_at" formik_props={formik_props} can_clear={false} />
                          </div>
                        }
                        
                      </Col>

                      <Col md={6}>
                        {this.renderNotesForm(formik_props)}
                      </Col>
                      
                    </Row>


                  </Col>
                </Row>

                <Separator variant="h50" />
                
                <Row>
                  <Col md={LEFT_WIDTH} css={section_name_style}>
                    Product settings
                  </Col>
                  <Col md={RIGHT_WIDTH}>
                    
                    <div>
                      { product.can_receive_faxes === true && <div>Receive faxes</div> }
                      { product.can_receive_faxes !== true && <div>Cannot receive faxes</div> }
                      
                      { product.can_send_faxes === true && <div>Send faxes</div> }
                      { product.can_send_faxes !== true && <div>Cannot send faxes</div> }
                      
                      { product.can_receive_voice === true && <div>Receive and forward voice messages</div> }
                      { product.can_receive_voice !== true && <div>Cannot receive voice</div> }
                    </div>
                    
                  </Col>
                </Row>

                <Separator variant="h50" />
                
                { this.renderTrafficInfo() }
                
                <Separator variant="h50" />

                { phone_number_is_relevant && this.renderNumberSection(formik_props) }

                { outgoing_fax_rate_is_relevant &&
                  <div>
                    <Row>
                      <Col md={LEFT_WIDTH} css={section_name_style}>
                        Outgoing fax rates
                      </Col>
                      <Col md={RIGHT_WIDTH}>
                        <Row>
                          <Col md="6">
                            Fax cost table
                            <FormikDropdownField name="fax_cost_structure_variant"
                                                 formik_props={formik_props}
                                                 placeholder="Select outgoing fax rate table"
                                                 options={fax_cost_structure_variant_options} />
                          </Col>
                          <Col md="6">
                            Minimum cost per fax (Euros, exVAT)
                            <FieldInfoText>
                              This cost applies for each combination of destination number and file, ie it maps to an api call to the fax sending server.
                            </FieldInfoText>
                            <FormikInputField
                                name="minimum_outgoing_fax_cost_excluding_vat_euros"
                                type="text"
                                placeholder="Euros"
                            />
                          </Col>
                        </Row>
                      </Col>
                    </Row>

                    <Separator variant="h50" />
                  </div>
                }

                { call_forwarding_cost_structure_variant_is_relevant &&
                  <AdminCallForwardingCostOptions formik_props={formik_props} RIGHT_WIDTH={RIGHT_WIDTH} LEFT_WIDTH={LEFT_WIDTH} />
                }

                { this.renderPriceFields(formik_props) }

                <Separator variant="h50" />
                
                { this.renderCustomerDescriptionOverrideFields(formik_props) }
                <Separator variant="h50" />

                { this.renderForwardingFields(formik_props) }
                <Separator variant="h50" />

                <Row>
                  <Col md={LEFT_WIDTH} css={section_name_style}>
                    Send created email
                  </Col>
                  <Col md={RIGHT_WIDTH}>
                    <Row>
                      <Col md="6">
                        <FieldInfoText>
                          If checked, then the customer will receive email&nbsp;
                          { get(formik_props, ["values", "payment_type"]) === 'prepaid' && "customer__new_subscription_created_by_admin" }
                          { get(formik_props, ["values", "payment_type"]) === 'postpaid' && "customer__new_postpaid_subscription_created_by_admin" }
                        </FieldInfoText>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="3">
                        <FormikCheckboxField name="send_subscription_created_email" label="Send new subscription email" />
                      </Col>
                    </Row>

                    { get(formik_props, ["values", "send_subscription_created_email"]) &&
                      <>
                      <Separator variant="h10" />
                      <Row>
                        <Col md="6">
                          <FieldInfoText>
                            If checked, then a payment link will be included in the new subscription email
                          </FieldInfoText>
                        </Col>
                      </Row>
                      <Row>
                        <Col md="3">
                          <FormikCheckboxField name="include_payment_link" label="Include payment link" />
                        </Col>
                      </Row>
                      </>
                    }
                  </Col>
                </Row>
                
                <Separator variant="h50" />
                <Row>
                  <Col md={LEFT_WIDTH} css={section_name_style}>
                  </Col>
                  <Col md={RIGHT_WIDTH}>
                    <BlueButton type="submit" disabled={formik_props.isSubmitting}>Save</BlueButton>
                  </Col>
                </Row>
              </Card>
            </div>
        )
    }

    renderNotesForm(formik_props) {
        return (
            <Row>
              <Col css={section_name_style}>
                Notes
                <Separator variant="h10" />
                <FormikTextarea name="internal_note"
                                placeholder="Your text here..."
                                formik_props={formik_props} />
              </Col>
            </Row>
        )
    }

    render() {
        const { initial_values, is_loading, is_busy, headers, customer, customer_id, breadcrumbs,
                subscription_statuses,
                customer_subscription, customer_subscription_id, is_ready } = this.props
        const { wizard_page, selected_product, creating_auto_payment_link, creating_subscription_upgrade_link,
                isShowingUpgradeToPaidVoiceSubscription } = this.state
        const that = this

        const new_status = head(filter(subscription_statuses, (x) => x.name === 'new'))
        
        const initial_values_with_defaults = Object.assign({},
                                                           {fax_cost_structure_variant: get(selected_product, "fax_cost_structure_variant"),
                                                            call_forwarding_cost_structure_variant: get(selected_product, "call_forwarding_cost_structure_variant"),
                                                            setup_price_excluding_vat_euros: get(selected_product, "setup_price_excluding_vat_euros"),
                                                            send_subscription_created_email: customer_subscription_id ? false : true,
                                                            include_payment_link: customer_subscription_id ? false : true,
                                                            phone_number: '',
                                                            payment_type: 'prepaid',
                                                            billing_frequency: 'annually',
                                                            base_cost_per_period_excluding_vat_euros: get(selected_product, "annual_subscription_price_excluding_vat_euros"),
                                                            status: get(new_status, "id"),
                                                            minimum_outgoing_fax_cost_excluding_vat_euros: get(selected_product, "minimum_outgoing_fax_cost_excluding_vat_euros"),
                                                            ab_call_forwarding_costs: map(get(selected_product, "ab_call_forwarding_costs"), (ab_call_forwarding_cost) => Object.assign({}, cloneDeep(ab_call_forwarding_cost), {id: null})),
                                                            monthly_num_free_voice_forwarding_minutes: get(selected_product, "monthly_num_free_voice_forwarding_minutes"),
                                                            prefix_set_qualifying_for_free_forwarding_minutes: get(selected_product, "prefix_set_qualifying_for_free_forwarding_minutes"),
                                                            voicemail_start_rate_excluding_vat_euros: get(selected_product, "voicemail_start_rate_excluding_vat_euros"),
                                                            voicemail_rate_per_minute_excluding_vat_euros: get(selected_product, "voicemail_rate_per_minute_excluding_vat_euros")
                                                           },
                                                           initial_values)

        return (
            <>
            <Formik
            initialValues={initial_values_with_defaults}
            onSubmit={that.onSave}
            enableReinitialize={true}
            validationSchema={validationSchema}
            >
            {formik_props => {
                const { values } = formik_props
                return (
                    <Form>
                      <FormikGeneralFormErrors render={(msg) => <Row><Col>{msg}</Col></Row>} />
                      <Row>
                        <Col>
                          { is_loading && <Loading /> }
                          { ! is_loading && wizard_page === 1 && that.renderSubscriptionPage1(formik_props) }
                          { ! is_loading && wizard_page === 2 && that.renderSubscriptionPage2(formik_props) }
                          { creating_auto_payment_link && that.renderAutoPaymentLinkModal() }
                          { creating_subscription_upgrade_link && that.renderSubscriptionUpgradeLinkModal() }
                        </Col>
                      </Row>
                    </Form>
                )}
            }
            </Formik>
            { isShowingUpgradeToPaidVoiceSubscription && that.renderUpgradeToPaidVoiceSubscription() }
            </>
        )
            }
    
}

function mapStateToProps(state, props) {
    const {customer_id, customer_subscription_id } = props
    const customer = adminCustomerList.getObject(customer_id)
    const customer_subscription = customer_subscription_id && adminCustomerSubscriptionForCustomerList.getObject(customer_subscription_id)
    const subscription_statuses = adminSubscriptionStatusList.getVisibleObjects()
    const subscription_status_options = map(subscription_statuses, function(ss) { return {value: ss.id, label: ss.display_name} })
    const selected_subscription_status_option = customer_subscription && head(filter(subscription_statuses, (ss) => ss.id === customer_subscription.status) )
    const fax_cost_structure_variant_options = adminFaxCostStructureVariantList.getAsOptions()
    const call_forwarding_cost_structure_variant_options = adminCallForwardingCostStructureVariantList.getAsOptions()
    const products = adminProductList.getVisibleObjects()
    const phone_number_prefix_set_options = adminPhoneNumberPrefixSetList.getAsOptions()
    const subscription_product = customer_subscription_id && size(products) > 0 && customer_subscription && adminProductList.getObject(customer_subscription.product)

    const breadcrumbs = [{name: 'admin_home'},
                         {name: 'customers', label: 'Customers', url: '/admin/customers'},
                         {name: 'customer', label: get(customer, ["user", "display_name"], ''), url: `/admin/customer/${customer_id}`}]
    if ( customer_subscription_id ) {
        breadcrumbs.push({name: 'customer_subscription', label: `Subscription for ${get(customer_subscription, ["number"], '(no number)')}`, 
                          url: `/admin/customer/${customer_id}/subscription/${customer_subscription_id}`})
    } else {
        breadcrumbs.push({name: 'customer_subscription', label: `New subscription`, 
                          url: `/admin/customer/${customer_id}/subscription/`})
    }
    
    return {
        customer_id,
        customer,
        customer_subscription,
        customer_subscription_id,
        products,
        product_options: adminProductList.getAsOptionsFullValue(),
        subscription_product,
        breadcrumbs,
        fax_cost_structure_variant_options,
        call_forwarding_cost_structure_variant_options,
        is_loading: adminCustomerSubscriptionForCustomerList.isLoading() ||
            adminCustomerList.isLoading() ||
            adminSubscriptionStatusList.isLoading(),
        is_busy: adminCustomerSubscriptionForCustomerList.getIsSavingObject(),
        is_ready: adminProductList.isReady(),
        initial_values: Object.assign({}, {number_selection_type: "default"}, customer_subscription),
        subscription_statuses,
        subscription_status_options,
        selected_subscription_status_option,
        subscription_for_customer_list_filter: adminCustomerSubscriptionForCustomerList.getFilter(),
        long_lived_otp_expire_in_hours: globalSettingsList.getSetting('long_lived_otp_expire_in_hours'),
        phone_number_prefix_set_options,
        countries_by_id: countryList.getObjectsById(),
        number_types_by_id: adminNumberTypes.getObjectsById(),
        upgradePaths: adminUpgradePathList.getVisibleObjects()
    }
}

export default withRouter(connect(mapStateToProps)(ReactTimeout(AdminCustomerSubscription)))

const main = css`
background-color: ${theme.colors.card_background};
`

const section_name_style = css`
font-weight: 500;
font-size: 18px;
`

const action_buttons_style = css`
display: flex;
width: 100%;
justify-content: flex-end;
`

const supplement_title_style = css`
display: flex;
`

const upgrade_path_action_buttons_style = css`
width: 100%;
`

const upgrade_path_action_button_style = css`
margin-top: 10px;
width: 100%;
display: flex;
justify-content: flex-end;
`

const low_traffic_warning_style = css`
color: ${theme.colors.warning_red};
`
